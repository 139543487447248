
/**
 * Work for messagebox and define the message type.
 * Value "Info", "Success", "Warning", "Error", "Validation"
 */
export enum MessageTypes {
    /** Normal information. */
    Info = 'Info',
    /** Process is done successfully. */
    Success = 'Success',
    /** Warning message. */
    Warning = 'Warning',
    /** Error message. */
    Error = 'Error',
    /** Server side business model validation error. */
    Validation = 'Validation',
    /**
     *  Work for showing dialog popup box to notify user his/her login session is going to expire in minutes.
     *  Thus, they could click 'Continue Session' to refresh their login token.
     *  Corporate with security.oidc.service.ts. event "addAccessTokenExpiring".
     */
    Expiration = 'Expiration',
    /**
     * If client side sjiVersionis not the same as server side, it means system got a new release.
     * System needs to notify end user to refresh his/her local browser to get latest version deployment package.
     */
    Refresh = 'Refresh'
 }
