// angular
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ApplicationRef, ErrorHandler } from "@angular/core";
import { RouterModule } from "@angular/router";
// Http request/response process associated.
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
// Work for UI components.
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// Work for multiple languages support.
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
////////////////////////////////////////////////////////////////////////////////
// ONE UI framework components
///////////////////////////////////////////////////////////////////////////////
import { SharedModule } from "../shared/shared.module";
import { HttpCSPHeadersInterceptorProvider } from "../core/interceptors/http.csp.headers.interceptor";
import { SecurityOidcGuardService } from "../core/services/system/security.oidc.guard.service";
import { SecurityOidcService } from "../core/services/system/security.oidc.service";
// Custom Services.
import { MessageService } from "../core/services/system/message.service";
import { LoaderService } from "../core/services/system/loader.service";
import { LoggerService } from "../core/services/system/logger.service";
// import { SecurityService } from '../core/services/system/security.service';
import { UserService } from "../core/services/system/user.service";
import { GlobalErrorHandlerProvider } from "../core/services/system/global.error.handler";
// Custom Components.
import { MessageComponent } from "../core/components/system/message/message.component";
import { DialogComponent } from "../core/components/system/dialog/dialog.component";
import { LoaderComponent } from "../core/components/system/loader/loader.component";
import { NotFoundComponent } from "../core/components/system/nofound/notfound.component";
// Custom Providers.
import { SecurityOidcInterceptorProvider } from "../core/interceptors/security.oidc.interceptor";
import { LoaderInterceptorProvider } from "../core/interceptors/loader.interceptor";
import { HttpErrorInterceptorProvider } from "../core/interceptors/http.error.interceptor";
// import { SecurityGuard } from '../core/services/system/security.guard';

////////////////////////////////////////////////////////////////////
// SJI application components
///////////////////////////////////////////////////////////////////
import { SJIService } from "./services/sji.service";
import { SJITranslateLoader } from "./services/sji.translate.loader";
// Custom Routing.
import { SJIRoutingModule } from "./app.routing";
// Custom Components.
import { SJISharedModule } from "./components/shared.module";
import { LoginComponent } from "../core/components/system/login/login.component";
import { RegisterComponent } from "../core/components/system/register/register.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
/** Note: SJIAppContextService is a singletone service. */
import { SJIAppContextService } from "../core/services/system/sji.appcontext.service";
import { UserProfileService } from "./userprofile/services/userprofile.service";
import { environment } from "src/environments/environment";
import { Idle, NgIdleModule } from "@ng-idle/core";

@NgModule({
  declarations: [
    MessageComponent,
    DialogComponent,
    HeaderComponent,
    LoaderComponent,
    LoginComponent,
    RegisterComponent,
    NotFoundComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    SJISharedModule,
    // Work for multiple languages support.
    TranslateModule.forRoot({
      // Note: Whole application only allows to have one "forRoot".
      loader: {
        provide: TranslateLoader,
        useFactory: SJITranslateLoader, // Load multiple languages files from assets/i18n/core/*.json.
        deps: [HttpClient],
      },
    }),
    HttpClientModule,
    SJIRoutingModule,
    NgIdleModule.forRoot(), // This should configure Idle and IdleExpiry
  ],
  //
  // Export the shared components which would be applied to other modules.
  // When CoreModule is imported in to the root AppModule, the exporeted components will be available
  // as a directive because of the "exports".
  //
  exports: [
    // Important. Without export RouteerModule here, system will reaise exception: 'router-outlet' is not a known element:
    RouterModule,
    // Note: Export to application's root, called in app.module.ts.
    // When application start, system will try to get multiple languages translate files from assets/i18n/core/*.json
    TranslateModule,
    MessageComponent,
    DialogComponent,
    HeaderComponent,
    LoaderComponent,
    // ThemeComponent,
    FooterComponent,
  ],
  // Note: The services setup inside providers will be singleton.
  providers: [
    Idle,
    HttpClient,
    MessageService,
    LoaderService,
    LoggerService,
    // ThemeService,
    TranslateService, // Form @ngx-translate.
    // SecurityGuard,
    // SecurityService,
    SecurityOidcGuardService,
    // SecurityOidcService,
    UserService,
    LoaderInterceptorProvider,
    HttpErrorInterceptorProvider,
    GlobalErrorHandlerProvider,
    SecurityOidcInterceptorProvider,
    SJIService,
    UserProfileService,
    // tslint:disable-next-line:max-line-length
    // SJIAppContextService // Contains shared parameters across the application. Check company domain. It is a global service. It is singleton services
  ],
  /**
   * Place components which are created dynamically to entryComponents under @NgModuledecorator function.
   */
  entryComponents: [
    MessageComponent,
    LoaderComponent,
    DialogComponent,
    HeaderComponent,
    FooterComponent,
  ],
})
/**
 * The declaration for CoreModule in app/core/core.module.ts must constain all shared components which will be shared with other modules.
 * The purpose of CoreModule is to hold the root components, services and features of the application
 * such as a universal login screen, global navbar/header, global footer, authentication and authentication guards.
 * Where lazy-loaded is needed, the other modules can easily be lazy-loaded in using the
 * following code in the core.routing.module.ts file.
 *
 * Reference: https://www.technouz.com/4644/angular-5-app-structure-multiple-modules/
 */
export class SJIModule {
  constructor() {
    // console.log("app.module");
    // console.log(environment);
  }
}
