import { InjectionToken } from '@angular/core';
/** Creates a token that can be used in a DI Provider. Keep themes collection. */
export const THEMES = new InjectionToken('THEMES');
/** Creates a token that can be used in a DI Provider. Keep current active theme name. */
export const ACTIVE_THEME = new InjectionToken('ACTIVE_THEME');

export interface Theme {
  name: string;
  properties: any;
}

export interface ThemeOptions {
  themes: Theme[];
  active: string;
}
