import { Injectable } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {
//     private subject = new Subject<any>();
//    constructor(private router: Router) {
//         //
//         // Hide the loader on route changed.
//         //
//         router.events.subscribe(event => {
//           if (event instanceof NavigationStart) {
//              this.showLoader();
//            } else if ( event instanceof NavigationEnd || event instanceof NavigationCancel) {
//              this.hideLoaderImmediately();
//           }
//         });
//     }

//     showLoader() {
//         this.subject.next({display: true});
//     }

//     hideLoader() {
//         // Http request sometime completed too fast, need to delay here.
//         setTimeout(() => {
//             this.subject.next();
//         }, 500);
//     }

//     hideLoaderImmediately() {
//         this.subject.next();
//     }

//     getDisplayStatus() {
//        return this.subject.asObservable();
//     }

   public isLoading = new BehaviorSubject(false);

   constructor() {}
}
