import { Injectable } from "@angular/core";
import { from } from "rxjs";
import { filter, map, switchMap, tap, windowCount } from "rxjs/operators";
import { IAppConfig } from "src/assets/app-config.model";
declare var window: any;

@Injectable()
export class AppInitService {
  // This is the method you want to call at bootstrap
  // Important: It should return a Promise
  public init() {
    return from(
      fetch("assets/app-config.json").then(function(response) {
        return response.json();
      })
    )
      .pipe(
        map((config: IAppConfig) => {
          window.config = config;

          if (!config.enableConsole) {
            if (window) {
              window.console.log = function() {};
            }
          }
          return;
        })
      )
      .toPromise();
  }
}
