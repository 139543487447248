import { Component, Input, Inject, EventEmitter, Output, ViewChild } from '@angular/core';
import { Lookup } from './models/lookup';

@Component({
  selector: 'combobox-dropdown',
  template: `<kendo-combobox #kcombo
               [data]="filteredData" [textField]="textField" [valueField]="valueField" [(ngModel)]="value" [valuePrimitive]="true"
               class="form-control" [placeholder]="placeholder" [readonly]="readonly" [disabled]="disabled"
               [filterable]="true" (filterChange)="handleFilter($event)" (valueChange)="onValueChanged($event)" (focus)="onFocus()">
            </kendo-combobox>`
})
export class ComboBoxDropDownComponent {
  @ViewChild('kcombo') kcombo: any;

  public filteredData: Lookup[] = [];
  private _data: Lookup[] = [];

  @Input() valueField: string = 'lookupValueId';
  @Input() textField: string = 'label';

  @Input() placeholder: string = 'Select';
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;

  @Input() set data(dataSource: Lookup[]) {
    this._data = dataSource;
    this.filteredData = dataSource;
  }

  @Input() value?: number;

  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

  public handleFilter(text: string): void {
    console.log('handleFilter', text);

    this.filteredData = this._data.filter(x => x[this.textField].toLowerCase().indexOf(text.toLowerCase()) !== -1);
  }

  public onValueChanged(value: number): void {
    console.log('onValueChanged()', value);

    if (this.value == undefined && (this.filteredData == null || this.filteredData.length == 0)){
      // nothing selected, reset filteredData to full list
      this.filteredData = this._data;
    }

    this.valueChange.emit(this.value);
  }

  public onFocus(): void {
    if (this.readonly == false && this.kcombo != null && this.kcombo.isOpen == false) {
      this.kcombo.toggle(true);
    }
  }
}
