declare var window: any;

export class DynamicEnvironment {

    public get apiRootUrl(){
        return window.config.apiRootUrl;
    }

    public get IAMUrl(){
        return window.config.IAMUrl;
    }    

    public get enableConsole(){
        return window.config.enableConsole;
    }
}
