import { APP_CONFIGS } from "./appconfigs";
/**
 * Any server side web api call urls put here.
 * It is applied in global.
 */
export class WebApiUrls {
  /**
   * User Login web Api call. "/user/authenticate".
   * public property.
   */
  public get login() {
    return APP_CONFIGS.apiRootUrl + "api/user/authenticate";
  }
  /**
   * client site exception logging in server side.
   * Web api call. "/logger/logging".
   */
  public get logging() {
    return APP_CONFIGS.apiRootUrl + "api/logger/addlog";
  }

  /** Regiser new user */
  public get register() {
    return APP_CONFIGS.apiRootUrl + "api/user/register";
  }

  public get accessByAdminRole() {
    return APP_CONFIGS.apiRootUrl + "api/identity/accessbyadminrole";
  }
  public get accessByCustomRole() {
    return APP_CONFIGS.apiRootUrl + "api/identity/accessbycustomrole";
  }
  public get accessByViewAccessRight() {
    return APP_CONFIGS.apiRootUrl + "api/identity/accessbyviewaccessright";
  }
  public get accessByUpdateAccessRight() {
    return APP_CONFIGS.apiRootUrl + "api/identity/accessbyupdateaccessright";
  }
  public get getidentity() {
    return APP_CONFIGS.apiRootUrl + "api/identity";
  }

  constructor(){

    // console.log("WebApiUrls.login");
    //console.log(APP_CONFIGS.apiRootUrl);
  }
}
/**
 * Global Const contains all web api all urls.
 *
 */
export const WEB_API_URLS = new WebApiUrls();
