import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';
import { SJIService } from '../../services/sji.service';
import { APP_CONFIGS } from 'src/app/_configs/appconfigs';
import { SJI_API_URLS } from '../../_configs/webapiurls';
import * as m from '../models/trackingOrders';
import { TrackingOrdersSearch } from '../models/trackingOrdersSearch';
import { DirectContainerResult } from '../models/directContainerResult';
import { WarehouseDetailsResult } from '../models/warehouseDetailsResult';
import { SJIAppContextService } from '../../../core/services/system/sji.appcontext.service';
import { saveAs } from '@progress/kendo-file-saver';
//import { environment } from '../../../../environments/environment';
import { SJIAppContext } from '../../../core/models/system/sjiappcontext';
import { MessageService } from '../../../core/services/system/message.service';

/**
 * Admin Module bootstrap component.
 * It is container for all other admin components.
 **/
@Component({
  selector: 'app-order-trackingPhoneOrders',
  templateUrl: './trackingPhoneOrders.component.html',
  styleUrls: ['./trackingPhoneOrders.component.scss']
})

export class TrackingPhoneOrdersComponent {
  sjiAppContext: SJIAppContext = new SJIAppContext();
  isIndex: boolean = false;
  isWarehouseModule: boolean = true;
  isDirectContainerModule: boolean = true;
  isTrackOrderDialog: boolean = false;
  isDCOrder: boolean = false;
  displayPhone: string;
  isDisableOrdering: boolean;
  numberOrder: string;
  WarehouseDialog: boolean;
  ContainerDialog: boolean;
  isaddCartSuccessfully: boolean = false;
  isChangePrice: boolean = false;
  isaddCartFailure: boolean = false;
  trackingOrdersSearch: TrackingOrdersSearch = { showType: '1', isIndex: true };
  orderList: m.TrackingOrders[] = [{}];
  showWarehouseRow: WarehouseDetailsResult = new WarehouseDetailsResult();
  showContainerRow: DirectContainerResult = new DirectContainerResult();
  currentItemInfo: any;
  isTrackingOrdersHidden: boolean = false;
  trackOrderNum: any;
  isNewTrackOrderModule = false;

  MaxamContainerDialog = {
    isShow: false,
    title: '',
    model: {},
    isDetail: false,
    detail: {currentStatus: 1},
    steps: [],
    height: 500
  }

  @Input() set IsIndex(value: boolean) {
    if (value) {
      this.isIndex = value;
    }
  }
  constructor(
    private sjiService: SJIService,
    private appContextService: SJIAppContextService,
    private router: Router,
    private messageService: MessageService
  ) {
    this.sjiAppContext = this.appContextService.getAppContext();

    let toDate = new Date();
    let formDate = new Date(toDate.setDate(toDate.getDate() - 180));
    this.trackingOrdersSearch.from = formDate;
    this.trackingOrdersSearch.to = new Date();
    //this.search();

    // Regist customerChanged event.
    this.appContextService.customerChanged
      .subscribe((companyCustomerCd: string) => this.customerChanged(companyCustomerCd));
    // Regist customerShippingAddressChanged event.
    this.appContextService.customerShipToChanged.
      subscribe((companyCustomerShippingAddressCd: string) => this.customerShippingAddressChanged(companyCustomerShippingAddressCd));
  }
  public apiRootUrl = APP_CONFIGS.apiRootUrl;
  public dropdownButtonData: Array<any> = [{
    text: 'PDF',
    icon: 'file-pdf',
    click: () => {
      this.downloadOrderList(1);
    }
  }, {
    text: 'Excel',
    icon: 'file-excel',
    click: () => {
      this.downloadOrderList(2);
    }
  }];

  ngOnInit() {
    this.getApp();
    this.search();
  }
  public getApp(){

    let data = this.appContextService.getAppContext();
    if (data.modules == null || (data.modules.indexOf(3) < 0 && data.modules.indexOf(16)<0)) {
      this.router.navigate(['home/index']);
    }
    if (data.modules.indexOf(1) < 0) {
      this.isDirectContainerModule = false;
    }
    if (data.modules.indexOf(2) < 0) {
      this.isWarehouseModule = false;
    }
    this.isNewTrackOrderModule = data.modules.indexOf(16) > -1;
    this.displayPhone = data.displayPhone;
    this.isDisableOrdering = data.isDisableOrdering;
  }

  public search() {
    let result: TrackingOrdersSearch = this.sjiService.cloneObject(this.trackingOrdersSearch);
    result.from = this.sjiService.theCorrectTimeZone(this.trackingOrdersSearch.from);
    result.to = this.sjiService.theCorrectTimeZone(this.trackingOrdersSearch.to);
    result.isIndex = this.isIndex;
    this.sjiService.post(SJI_API_URLS.order_tracking_phone_order_search, result).subscribe((data) => {
      this.orderList = data as m.TrackingOrders[];
      console.log("Phone order search ----------------------------------");
      console.log(data);
    });
  }

  public downloadOrderList(fileType: number) {
    this.trackingOrdersSearch.fileType = fileType;
    let fileName = 'orderlist';
    if (fileType === 1) {
      fileName += '.pdf';
    } else if (fileType === 2) {
      fileName += '.xlsx';
    }
    this.sjiService.postDownload(SJI_API_URLS.order_tracking_downloadorderlist, this.trackingOrdersSearch)
      .subscribe(data => saveAs(data, fileName));
  }
  public printOrder(orderId: string) {
    const url = `${SJI_API_URLS.order_tracking_printorder}?orderId=${orderId}`;
    this.sjiService.postDownload(url, {}).subscribe(data => saveAs(data, orderId + '.pdf'));
  }
  public warehouseclose(status) {
    this.WarehouseDialog = false;
  }
  public containerclose(status) {
    this.ContainerDialog = false;
  }
  public change_type() {
    this.isTrackingOrdersHidden = !this.isTrackingOrdersHidden;
  }
  public trackingOrder(orderNum, isDirectContainer) {
    this.isTrackOrderDialog = false;
    // this.sjiService.get(SJI_API_URLS.order_tracking_orderTracking, { orderNum: orderNum }).subscribe((data) => {
    //   var moduleId = data;
    //   if (moduleId == 1)
    //     this.showDirectContainerDetails(orderNum);
    //   else if (moduleId == 2)
    //     this.showWarehouseDetails(orderNum);
    //   else {
    //     this.isTrackOrderDialog = true;
    //     if (orderNum != null && orderNum != '' && orderNum.indexOf('CD') > -1)
    //       this.isDCOrder = true;
    //     else
    //       this.isDCOrder = false;
    //   }
    // });

    if (isDirectContainer){
      console.log("Show DC : " + orderNum);
      this.isDCOrder = true;
      if(this.isNewTrackOrderModule){
        this.showMaxamDirectContainerDetails(orderNum);
      }else{
        this.showDirectContainerDetails(orderNum);
      }

    }else{
      console.log("Show EC : " + orderNum);
      this.isDCOrder = false;
      this.showWarehouseDetails(orderNum);
    }
  }
  public addToCart(dataItem: m.TrackingOrders) {
    //this.currentItemInfo = dataItem;
    let items = dataItem.items.filter(m => m.isCheck == true);
    if (items != null && items.length > 0) {
      let salesOrderDetailIds = [];
      for (let index = 0; index < items.length; index++) {
        salesOrderDetailIds.push(items[index].id);

      }
      this.sjiService.post(SJI_API_URLS.order_tracking_addtocart, { salesOrderDetailIds: salesOrderDetailIds }).subscribe((data) => {
        this.currentItemInfo = data;
        console.log("addcart", this.currentItemInfo);
        if (this.currentItemInfo["isSuccess"]) {
          this.isaddCartSuccessfully = true;
          this.isChangePrice = this.currentItemInfo["isChangePrice"];
          this.sjiService.common_gettotalcount();
        }
        else
          this.isaddCartFailure = true;
      });
    }
  }
  public getImageSrc(id: string, level: number): string {
    let url = `${SJI_API_URLS.common_images_showproductline}?id=${id}&level=${level}`;
    let imageUrl = url;
    return imageUrl;
  }
  public showWarehouseDetails(orderId: string) {
    let url = `${SJI_API_URLS.order_tracking_showwarehousedetailforphoneorders}?orderId=${orderId}`;
    console.log('showWarehouseDetails: ' + url);
    this.sjiService.get(url).subscribe((data) => {
      console.log("showWarehouseRow", data);
      this.showWarehouseRow = data as WarehouseDetailsResult;
      this.WarehouseDialog = true;
    });
  }
  public showDirectContainerDetails(orderId: string) {
    let url = `${SJI_API_URLS.order_tracking_showdirectcontainerdetailforphoneorders}?orderId=${orderId}`;
    console.log('showDirectContainerDetails: ' + url);
    this.sjiService.get(url).subscribe((data) => {
      console.log("showWarehouseRow", data);
      this.showContainerRow = data as DirectContainerResult;
      this.ContainerDialog = true;
    });
  }
  public showMaxamDirectContainerDetails(orderId: string) {
    let url = `${SJI_API_URLS.order_tracking_ordertrackingforphonedetail}?id=${orderId}`;
    this.sjiService.get(url).subscribe((result: any) => {
      if (result.isSuccess) {
        this.MaxamContainerDialog.model = result.data;
        this.MaxamContainerDialog.title = "Tracking.OrderTracking";
        this.MaxamContainerDialog.height = 500;
        this.MaxamContainerDialog.isShow = true;
        this.MaxamContainerDialog.isDetail = false;
      } else {
        this.messageService.error(result.message);
        this.messageService.emitMessages();
      }
    });
  }
  public showContainerDetail(item: any) {
    this.MaxamContainerDialog.detail = item;
    this.MaxamContainerDialog.title = "Tracking.ContainerList";
    this.MaxamContainerDialog.height = item.containerNum > 2 ? 850 : 650;
    this.MaxamContainerDialog.isDetail = true;
    this.MaxamContainerDialog.steps = [
      { type: 1, title: "Tracking.Factory", description: item.factoryDesc, etd: item.factoryETD, atd: item.factoryATD},
      { type: 0, title: "Tracking.ToBeShipped"},
      { type: 1, title: "Tracking.PortOfLoading", description: item.polDesc, etd: item.polETD, atd: item.polATD},
      { type: 0, title: "Tracking.InTransit"},
      { type: 1, title: "Tracking.PortOfDischarge", description: item.podDesc, etd: item.podETAUpdate, atd: item.podATA},
      { type: 0, title: "Tracking.InTransit"},
      { type: 1, title: "Tracking.CarrierFnd", description: item.carrierFndDesc, etd: item.carrierFndETA, atd: item.carrierFndATA},
      { type: 0, title: "Tracking.Delivering"},
      { type: 1, title: "Tracking.FinalDestination", description: item.cargoFndDesc, etd: item.cargoFndETA, atd: item.cargoFndATA},
      { type: 0, title: "Tracking.EmptyReturning"},
      { type: 1, title: "Tracking.ContainerYard", description: item.carrierYardDesc, etd: item.carrierYardETA, atd: item.carrierYardATA}
    ];
  }
  public containerClose() {
    if(this.MaxamContainerDialog.isDetail){
      this.MaxamContainerDialog.title = "Tracking.OrderTracking";
      this.MaxamContainerDialog.height = 500;
      this.MaxamContainerDialog.isDetail = false;
    }else{
      this.MaxamContainerDialog.isShow = false;
    }
  }
  // public printInvoice(invoiceNum: string) {
  //   console.log(invoiceNum);
  // }
  public carClose() {
    this.isaddCartSuccessfully = false;
  }
  public failureClose() {
    this.isaddCartFailure = false;
  }
  public goCartPage() {
    this.router.navigate(['cart/index']);
  }
  public downloadInvoice(invoiceNo: string) {
    // const url = `${SJI_API_URLS.documentcenter_invoiceandcreditmemoreport}?invoiceId=${invoiceNo}`;
    const url = `${SJI_API_URLS.documentcenter_getvarioussalesreport}?number=${invoiceNo}`;
    this.sjiService.postDownload(url, {})
      .subscribe(data => saveAs(data,
        invoiceNo + '.pdf'));
  }
  public downloadShipment(shipmentId: string, containerNum: string) {
    const url = `${SJI_API_URLS.documentcenter_downloadshipment}?shipmentId=${shipmentId}`; //&containerNo=${containerNum}`;
    //const url = `${SJI_API_URLS.documentcenter_getvarioussalesreport}?number=${shipmentId}`;
    this.sjiService.postDownload(url, {})
      .subscribe(data => saveAs(data,
        shipmentId + '_' + containerNum + '.pdf'));
  }
  public trackOrderDialogClose() {
    this.isTrackOrderDialog = false;
  }

  private customerChanged(companyCustomerCd: string) {
    this.getApp();
    this.search();
  }

  private customerShippingAddressChanged(companyCustomerShippingAddressCd: string) {
    this.getApp();
    this.search();
  }
}

