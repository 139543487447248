import { ThemingService } from "./../core/theming/theming.service";
import { SecurityOidcService } from "../core/services/system/security.oidc.service";

import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { Languages } from "../_enumerations/languages";
import { TranslateService } from "@ngx-translate/core";
import { SJIAppContextService } from "../core/services/system/sji.appcontext.service";
import { SJIAppContext } from "../core/models/system/sjiappcontext";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { MessageService } from "../core/services/system/message.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: [
    "../themes/OneUI/all.scss",
    "./app.component.scss",
    "../../assets/scss/font-awesome.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SJIAppComponent implements OnInit {
  loaded = false;
  loggedIn = false;
  sjiAppContext: SJIAppContext = new SJIAppContext();
  idleState = "Not started.";
  // Work for Idle process.
  isTimeout = false;

  // title = 'Dapasoft Angular 5 template';
  // enableDynamicTheme = APP_CONFIGS.enableDynamicTheme;
  // tslint:disable-next-line:max-line-length
  constructor(
    private translateService: TranslateService,
    private securityService: SecurityOidcService,
    private appContextService: SJIAppContextService,
    private themeService: ThemingService,
    private router: Router,
    private titleService: Title,
    private idle: Idle,
    private messageService: MessageService
  ) {
    const self = this;
  } // End of construtor.

  /**
   * this reason calling ngOnInit here is
   * we cannot declare a class constructor as async.
   * As a workaround we could take advantage of Angular’s lifecycle methods
   * and move our initialisation code to ngOnInit, marking that method as async.
   *
   * Reference: https://labs.encoded.io/2016/12/08/asyncawait-with-angular/
   */
  async ngOnInit() {
    // console.log('start call sji appComponent async ngOnInit');
    //
    // Call appContextService.init first.
    // Note: Only call one time appContextservice.init here.
    //
    await this.appContextService.initDomain();
    const logonUser = await this.securityService.init();
    console.log(
      "securitySevice.init completed. Logon User: " + JSON.stringify(logonUser)
    );
    this.loggedIn = this.securityService.isLoggedIn();
    console.log("sji appcomponent loggedIn = " + this.loggedIn);
    await this.appContextService.initContext();

    this.sjiAppContext = this.appContextService.getAppContext();
    if (
      this.sjiAppContext.mobileWebsite &&
      this.sjiAppContext.mobileWebsite.length > 0
    ) {
      const isMobileDevic =
        /Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(
          navigator.userAgent
        );
      const width = document.documentElement.clientWidth;
      const height = document.documentElement.clientHeight;
      const isMobile = isMobileDevic && (width <= 768 || height <= 768);
      if (isMobile) {
        window.location.href = this.sjiAppContext.mobileWebsite;
        return;
      }
    }
    //
    // After appContextService.init(), call themingService.init().
    //
    this.themeService.init();

    if (
      location.href.indexOf("auth-callback") > 0 ||
      location.href.indexOf("silent-refresh") > 0
    ) {
      // callback page, we did nothing
      this.loaded = true;
    } else {
      if (!this.loggedIn) {
        // console.log('before call login');
        // User has to login first.
        await this.securityService.login();
      } else {
        this.loaded = true;
      }
    }

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.addLangs([
      Languages.English_US,
      Languages.French_CA,
      Languages.Chinese_CHS,
    ]);
    // this.translateService.setDefaultLang(Languages.English_US);
    // const language = this.securityService.getUserLanguage();
    // this.translateService.getTranslation(language);
    this.translateService.use(this.appContextService.getLanguage());

    if (this.appContextService.getAppContext().isTempPasswordLogin) {
      // If user logined, but he/she is login with temporary password,
      // system will push them to resetpassword page to reset new password first.
      this.router.navigate(["userprofile/resetpassword"]);
    }
    this.titleService.setTitle(this.appContextService.appContext.companyName);

    //
    // Below codes works for force client app logout if idle about 20 minutes.
    //
    const systemIdleTimeout = 20 * 60; // 20 minutes idle
    const systemTimeout = 30; // seconds. warning for 30 seconds before apply logout.

    // Set idle and timeout values
    this.idle.setIdle(systemIdleTimeout); // in seconds
    this.idle.setTimeout(systemTimeout); // in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // Define responses to idle events
    this.idle.onIdleEnd.subscribe(() => this.handleIdleEnd());
    this.idle.onTimeout.subscribe(() => this.handleTimeout());
    this.idle.onIdleStart.subscribe(() => this.handleIdleStart());
    this.idle.onTimeoutWarning.subscribe((countdown) =>
      this.handleTimeoutWarning(countdown)
    );
    this.idle.watch();
  }

  handleIdleEnd() {
    this.idleState = "No longer idle.";
    // Reset logic
    this.reset();
  }

  handleTimeout() {
    this.idleState = "Logout!";
    // Logout logic
    this.securityService.signout();
  }

  handleIdleStart() {
    this.idleState = "You will be logged out soon!";
    // Show warning dialog
  }

  handleTimeoutWarning(countdown) {
    this.isTimeout = true;
    this.idleState = this.translateService
      .instant("Core.IdleMessage")
      .replace("#countdown#", countdown);
  }

  reset() {
    this.idle.watch();
    this.isTimeout = false;
  }

  continueLogin() {
    this.reset();
  }
}
