import { environment } from "../../environments/environment";
/** The app config class is used to store application config variables (like the api endpoint url)
 * in a single place that's easily injected into any component.
 * It is applied in global.
 */
export class AppConfigs {
  /** Default date short format. */
  public readonly dateFormat = "yyyy-MM-dd";
  /** Default datetime long format. */
  public readonly dateTimeFormat = "yyyy-MM-dd h:mm:ss a";
  /** Work for process which difference between development environment and production environment. */
  public readonly isProductionMode = false;
  /** If the web api server is hosting in differnt domain or port, need to use this setting to generate api call url. */
  //public readonly apiRootUrl = environment.apiRootUrl; //'http://localhost:56693/'; // SJI web api domain.
  public get apiRootUrl() {
    // console.log("AppConfigs.apiRootUrl");
    // console.log(environment);
     //return "http://localhost:56693/";
    return environment.apiRootUrl;
  } //'http://localhost:56693/'; // SJI web api domain.
  /** Default language for application. Work for multiple language support. */
  public readonly defaultLanguage = "en-us";
  /** Defined the application applied theme. */
  public readonly defaultTheme = "pink";
  /** If value = true, send angular application logging information or exceptions back to server side. corporate with web api call.
   * Note: enable this setting could cause performance issue. Convern.
   */
  public readonly sendClientLogToServer = false;

  public readonly enableDynamicTheme = false;
  /** It is Identity server 4 root url. work for security.oidc.service. */
  // public readonly IAMUrl = environment.IAMUrl;
  public get IAMUrl() {
    // console.log("exec get IAMUrl()");
    // console.log(environment.IAMUrl);
    // console.log(environment);
    //return "http://localhost:57052/";
    return environment.IAMUrl;

  }

  public getIAMUrl() {
    // console.log("exec getIAMUrl()");
    // console.log(environment.IAMUrl);
    //return "http://localhost:57052/";
    return environment.IAMUrl;

  }  
  /** Value of seconds. time before access token expire. Now, it is 180 seconds / 3 minutes before access token expire. */
  public readonly accessTokenExpiringNotificationTime = 180;
  /**
   *  Work for Oidc client setting. When value = true, no need to show popup warning message dialog to user for manual continue session.
   *  if value = false, system will display popup warning dialog to use for manually continue session.
   *  Corporate with dialog.component.ts and security.oidc.service.
   */
  public readonly isAccessTokenAutoRenew = true;

  constructor(){


  }
}

/**
 * Global const contains all custom settings.
 */
export const APP_CONFIGS = new AppConfigs();
