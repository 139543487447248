import { Theme } from '../../models/system/theme';

/**
 * Theme "Maxam_Tire_NA"
 * Reference to setting in table dbo.Theme.
 */
export const Theme_Maxam_Tire_NA: Theme = {
  name: 'Maxam_Tire_NA',
  properties: {
    /* own color */
    '--mainColor': '#1070B6', // Color 2
    '--mainButtomColor': '#1070B6', // main Buttom
    '--minorColor': '#000000', // Color 3
    '--mainColorB':'#1070B6',
    '--subColor': '#36B53C', // Color 1
    '--mainBackgroundColor': '#062D49', // Background
    '--mainTitleColor':'#f2f4f9',
    '--minorBackgroundColor': '#FFFFFF',
    '--importantLabel': '#333',
    '--ordinary_label': '#666',
    '--nonImportantLabel': '#999',
    '--dashColor1': '#abb5c4',
    '--dashColor2': '#A2AAB9',
    '--controlColor_span1': '#1F2125',
    '--controlInptBackgroundColor1': '#f3f2f2',
    '--navTitleColor': '#FFFFFF',
    '--navTitleMinorColor': '#000000',

    '--extraPriceColor':'#287f3d',
    '--inTransitColor':'#0435c5',
    '--cartIconColor':'#FFFFFF',
    '--grid_th_Color': '#F6F6F6',
    '--grid_td_Color': '#FFFFFF',


    /*hearder*/
    '--navbarBackground': '#FFFFFF',
    '--headerClickColor': '#1070B6',
    '--headerClickColor_border': '#090e2126',

    /*order*/
    '--grid_thColor': '#F6F6F6',
    '--controlColor_order_buttom1': '#1070B6',
    '--controlColor_order_buttom2': '#36B53C',
    '--controlColor_order_buttom3': '#090E22',
    '--controlColor_order_buttom4': '#36B53C',
    '--controlColor_buttomBorder1': '#36B53C',

    /*cart*/
    '--controlColor_buttom2': '#090E22',
    '--controlColor_buttom3': '#1070B6',
    '--controlColor_buttom2_font': '#FFFFFF',
    '--dialogBackgroundColor': '#FFFFFF',

    '--noFillColor':'#62697b',
    '--disableCarat':"#ccc",

    /*Direct Container Order Grid */
     '--GridRightBorderTH': '#A2AAB9',
     '--GridRightBorderTD': '#CCC',
     '--GridHoverColorTR':'#cce6ff',
     '--TextOnHover': '#FFFFFF'

    /* Bootstrap css override */
  }
};
