import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { AppInitService } from "./app.init.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";
// run the SJI application
import { SJIModule } from "./sji/app.module";
import { SJIAppComponent } from "./sji/app.component";
import {
  ThemingModule,
  Theme_Blacklion,
  Theme_Default,
  Theme_Dynamaxx,
  Theme_Dynamic_Tire,
  Theme_Ironhead,
  Theme_Maxam_Tire_NA,
  Theme_SJI_NA,
  Theme_Summit_Tire,
  Theme_Roadx,
  Theme_Blackhawk,
  Theme_VogueTyre,
  Theme_Vendor,
  Theme_Vendor8048,
  Theme_Vendor1401,
  Theme_Vendor0922,
  Theme_Vendor5419,
  Theme_Vendor5379,
  Theme_Vendor2211,
  Theme_Vendor8080,
  Theme_Vendor6066,
  Theme_Vendor2030,
  Theme_Vendor5996,
  Theme_Vendor1402,
  Theme_Vendor5469,
  Theme_Vendor2870,
  Theme_Vendor5205,
  Theme_Vendor8031,
} from "./core/theming";
import { SharedModule } from "./shared/shared.module";

export function init_app(appLoadService: AppInitService) {
  return () => appLoadService.init();
}

@NgModule({
  declarations: [SJIAppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    HttpClientJsonpModule,
    SJIModule,
    ThemingModule.forRoot({
      themes: [
        Theme_Blacklion,
        Theme_Default,
        Theme_Dynamaxx,
        Theme_Dynamic_Tire,
        Theme_Ironhead,
        Theme_Maxam_Tire_NA,
        Theme_SJI_NA,
        Theme_Summit_Tire,
        Theme_Roadx,
        Theme_Blackhawk,
        Theme_VogueTyre,
        Theme_Vendor,
        Theme_Vendor8048,
        Theme_Vendor1401,
        Theme_Vendor0922,
        Theme_Vendor5419,
        Theme_Vendor5379,
        Theme_Vendor2211,
        Theme_Vendor8080,
        Theme_Vendor6066,
        Theme_Vendor2030,
        Theme_Vendor5996,
        Theme_Vendor1402,
        Theme_Vendor5469,
        Theme_Vendor2870,
        Theme_Vendor5205,
        Theme_Vendor8031,
      ], // Companies' custom themes and default theme.

      active: "Default", // By defult, the active theme is "Default".
    }),
  ],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService],
      multi: true,
    },
  ],
  bootstrap: [SJIAppComponent],
})
/**
 * The AppModule which occupies the root of the folder is purposely kept as bare as possible.
 * It’s role is simply to bootstrap the Angular application, and provide the root-level router-outlet.
 * This approach also leaves open the possibility of running multiple,
 * independent Angular applications through the same base URL.
 * It also introduces the idea of building a router-driven Angular application.
 * Reference: https://www.technouz.com/4644/angular-5-app-structure-multiple-modules/
 */
export class AppModule {
  constructor() {}
}
