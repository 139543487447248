import { Theme } from '../../models/system/theme';

/**
 * Theme "SJI_NA"
 * Reference to setting in table dbo.Theme.
 */
export const Theme_SJI_NA: Theme = {
  name: 'SJI_NA',
  properties: {
    /* own color */
    '--mainColor': '#E5A133', // Color 2
    '--mainButtomColor': '#E5A133', // main Buttom
    '--minorColor': '#1B4065', // Color 3
    '--mainColorB':'#B37510',
    '--subColor': '#1B4065', // Color 1.
    '--mainBackgroundColor': '#1B4065', // Background
    '--mainTitleColor':'#f2f4f9',
    '--minorBackgroundColor': '#FFFFFF', // NAV
    '--importantLabel': '#333',
    '--ordinary_label': '#666',
    '--nonImportantLabel': '#999',
    '--dashColor1': '#abb5c4',
    '--dashColor2': '#1B4065',
    '--controlColor_span1': '#E5A133',
    '--controlInptBackgroundColor1': '#f3f2f2',
    '--navTitleColor': '#0c1827',
    '--navTitleMinorColor': '#FFFFFF',

    '--extraPriceColor':'#287f3d',    
    '--inTransitColor':'#0435c5',
    '--cartIconColor':'#FFFFFF',  
    '--grid_th_Color': '#F6F6F6',
    '--grid_td_Color': '#FFFFFF',


    /*hearder*/
    '--navbarBackground': '#000000',
    '--headerClickColor': '#E5A133',
    '--headerClickColor_border': '#090e2126',

    /*order*/
    '--grid_thColor': '#F6F6F6',
    '--controlColor_order_buttom1': '#E5A133',
    '--controlColor_order_buttom2': '#1B4065',
    '--controlColor_order_buttom3': '#090E22',
    '--controlColor_order_buttom4': '#1B4065',
    '--controlColor_buttomBorder1': '#1B4065',

    /*cart*/
    '--controlColor_buttom2': '#090E22',
    '--controlColor_buttom3': '#1B4065',
    '--controlColor_buttom2_font': '#FFFFFF',
    '--dialogBackgroundColor': '#FFFFFF',

    '--noFillColor':'#62697b',
    '--disableCarat':"#ccc",

    /*Direct Container Order Grid */
    '--GridRightBorderTH': '#1B4065',
    '--GridRightBorderTD': '#CCC',
    '--GridHoverColorTR':'#cce6ff',
    '--TextOnHover': '#FFFFFF'

    /* Bootstrap css override */
  }
};
