import { Theme } from '../../models/system/theme';

/**
 * Theme "Blackhawk". Reference to setting in table dbo.Theme.
 * Note: Blackhawk skin defintion is incorrect. Color1 should be Color2.
 */
export const Theme_Blackhawk: Theme = {
  name: 'Blackhawk', // Same as value of field "Code" in table dbo.Theme.
  properties: {
    /* own color */
    '--mainColor': '#FFDA0F', // Color 2
    '--mainButtomColor': '#FFDA0F', // main Buttom
    '--minorColor': '#000000', // Color 3
    '--mainColorB':'#9e1f98',
    '--subColor': '#4E4E4E', // Color 1.
    '--mainBackgroundColor': '#000000', // Background
    '--mainTitleColor':'#f2f4f9',
    '--minorBackgroundColor': '#FFFFFF',
    '--importantLabel': '#333',
    '--ordinary_label': '#666',
    '--nonImportantLabel': '#999',
    '--dashColor1': '#abb5c4',
    '--dashColor2': '#000000',
    '--controlColor_span1': '#9e1f98',
    '--controlInptBackgroundColor1': '#f3f2f2',
    '--navTitleColor': '#000000',
    '--navTitleMinorColor': '#FFFFFF',

    '--extraPriceColor':'#287f3d;',    
    '--inTransitColor':'#0435c5',
    '--cartIconColor':'#FFFFFF',  
    '--grid_th_Color': '#F6F6F6',
    '--grid_td_Color': '#FFFFFF',

    /*hearder*/
    '--navbarBackground': '#000000',
    '--headerClickColor': '#FFDA0F',
    '--headerClickColor_border': '#090e2126',

    /*order*/
    '--grid_thColor': '#F6F6F6',
    '--controlColor_order_buttom1': '#FFDA0F',
    '--controlColor_order_buttom2': '#000000',
    '--controlColor_order_buttom3': '#090E22',
    '--controlColor_order_buttom4': '#090e21',
    '--controlColor_buttomBorder1': '#FFDA0F',

    /*cart*/
    '--controlColor_buttom2': '#090E22',
    '--controlColor_buttom3': '#4E4E4E',
    '--controlColor_buttom2_font': '#FFFFFF',
    '--dialogBackgroundColor': '#FFFFFF',

    '--noFillColor':'#474747',
    '--disableCarat':"#ccc",

    /*Direct Container Order Grid */
    '--GridRightBorderTH': '#000000',
    '--GridRightBorderTD': '#CCC',
    '--GridHoverColorTR':'#CCC',
    '--TextOnHover': '#FFFFFF'
    /* Bootstrap css override */
  }
};
