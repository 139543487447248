import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SecurityOidcGuardService } from '../core/services/system/security.oidc.guard.service';

import { NotFoundComponent } from '../core/components/system/nofound/notfound.component';

/**
 * Note: Here it maintains all route entries to other modules.
 * It is the start entry for whole application.
 * Reference: https://www.technouz.com/4644/angular-5-app-structure-multiple-modules/
 */
const sjiRoutes: Routes = [
  {
    path: '',
    canActivate: [SecurityOidcGuardService], // Required user authentication. Corporate with Identity Server 4 and oidc-client library.
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    canActivate: [SecurityOidcGuardService], // Required user authentication. Corporate with Identity Server 4 and oidc-client library.
    loadChildren: './home/home.module#HomeModule'
  },
  {
    path: 'cart',
    canActivate: [SecurityOidcGuardService], // Required user authentication. Corporate with Identity Server 4 and oidc-client library.
    loadChildren: './cart/cart.module#CartModule'
  },
  {
    path: 'claims',
    canActivate: [SecurityOidcGuardService], // Required user authentication. Corporate with Identity Server 4 and oidc-client library.
    loadChildren: './claims/claims.module#ClaimsModule'
  },
  {
    path: 'order',
    canActivate: [SecurityOidcGuardService], // Required user authentication. Corporate with Identity Server 4 and oidc-client library.
    loadChildren: './order/order.module#OrderModule'
  },
  {
    path: 'userprofile',
    // Note: Cannot set canActive here. Need to be enable to access userprofile/resetpassword by public.
    loadChildren: './userprofile/userprofile.module#UserProfileModule'
  },
  // {
  //   /** Corporate with getOidcClientSettings method and SecurityOidcService. Identity Server integration need. Don't move it. */
  //   path: 'auth-callback',
  //   component: AuthCallbackComponent
  // },
  // {
  //   /** Corporate with getOidcClientSettings method and SecurityOidcService. Identity Server integration need. Don't move it. */
  //   path: 'silent-refresh',
  //   component: SilentRefreshComponent
  // },
  {
    path: 'vendor',
    loadChildren: './vendor/vendor.module#VendorModule'
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

/**
 * Note: Here it maintains all route entries to other modules.
 * It is the start entry for whole application.
 * Reference: https://www.technouz.com/4644/angular-5-app-structure-multiple-modules/
 */
@NgModule({
  imports: [RouterModule.forRoot(sjiRoutes)],
  exports: [RouterModule]
})
export class SJIRoutingModule { }
