/**
 * Languages enumeration definitions. Work for multiple languages support.
 * Enumeration value is string of culture code.
 * corporate with multiple languages json files' names in folder assets/i18n/
 */
export enum Languages {
  /** English - United States */
   English_US = 'en-us',
   /** French - Canada */
   French_CA = 'fr-ca',
   /** Spanish - United States */
   Spanish_US = 'es-us',
   /** Chinese Traditional */
   // Chinese_CHT = 'zh-cht'
   /** Chinese Simplified */
    Chinese_CHS = 'zh-chs',
}
