import { MessageTypes } from './../../../_enumerations/messagetypes';
import { MessageService } from './message.service';
import { APP_CONFIGS } from './../../../_configs/appconfigs';
import { SJIAppContext } from './../../models/system/sjiappcontext';
import { SJIAppContextService } from './sji.appcontext.service';
import { Injectable } from '@angular/core';
import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { Output, EventEmitter } from '@angular/core';
import { APP_CONSTS } from '../../../_configs/consts';

/**
 * Work for user authorization.
 * Work for communicate with Identity Server 4 solution with Open Id Connect from Angular6 application.
 * Corporate with oidc-client library.
 * Reference: https://www.scottbrady91.com/Angular/SPA-Authentiction-using-OpenID-Connect-Angular-CLI-and-oidc-client
 */
@Injectable(
  {
   /**
   * Define SecurityOidcService as a shared service.
   * Angular creates a single, shared instance of the service and injects into any class that asks for it.
   * Reference: http://www.talkingdotnet.com/providing-shared-instance-of-service-in-angular-6/
   */
   providedIn: 'root',
}
)
export class SecurityOidcService {
  public manager: UserManager;
  /** User is defined in oidc-client library. */
  private user: User = null;
  private currentDomain = '';
  /**
   * Subject() is doing both Emitting data and Subscribing it in another component.
   * So its the best way to compare with Emitting using Output.
   */
  @Output() getUserClaims: EventEmitter<any> = new EventEmitter();

  /** Each time initialize service, system call getUser() to get current logon user's information. */
  constructor(public appContextService: SJIAppContextService, public messageService: MessageService) {
    this.currentDomain = this.getCurrentDomain();
    /**
     * Note: Important! Need to initialize manager in constructor since following methods need to call manager.
     * But one menthod "init()" need to reinstance manager again.
     * Since before call init(), appContextService got back the company Id which work as client Id.
     */
    this.manager = new UserManager(getOidcClientSettings(this));
    this.manager.events.addSilentRenewError(function (error) {
      console.error('error while renewing the access token', error);
    });

    /**
     * Corporate with oidc setting "accessTokenExpiringNotificationTime".
     * Raise event to notify the accessToken is going to expire.
     */
    this.manager.events.addAccessTokenExpiring(() => {
      //
      // Note: If set OidcClientSetting.automaticSilentRenew = true,
      // we don't need to open Expire Dialog to client.
      // System will auto renew access token on backend.
      //
      // If not auto renew, system will popup an warning dialog to user before access token expired.
      //
      if (!APP_CONFIGS.isAccessTokenAutoRenew) {
         this.showGoingToExpireDialog();
      }
    });

    /**
     *  If Access Token expired, try to silent sign again.
     */
    this.manager.events.addAccessTokenExpired(() => {
      console.log('AccessToken expired.');
      this.accessTokenExpired();
    });

    this.manager.events.addSilentRenewError(function(err) {
      console.log('SilentRenewError: ' + err);
    });

   // this.manager.events.addUserSignedOut(() => {
   //   console.log('UserSignedOut event raised');
   //   // redirect back to login page in identity server.
   //   this.signout();
   // });

    this.manager.events.addUserLoaded((user: User) => {
       console.log('UserLoaded event raised: ' + JSON.stringify(user));
       this.userLoaded(user);
    });

    this.manager.events.addUserUnloaded(() => {
      console.log('UserUnloaded event raised');
      this.userUnloaded();
    });

    /**
     *  Note: Important! have to assign value to this.user in constructor.
     * since security.oidc.service need to inject access token into request header.
     * */
    this.manager.getUser().then(user => {this.user = user; });

    // this.user = this.getLogonUser();
    // this.manager.getUser().then(user => {
    //      if (user) {
    //        if (!user.expired) {
    //           this.user = user;
    //           this.getUserClaims.emit(this.getClaims());
    //           // Refresh local storage's logon user entity.
    //           this.setLogonUser(this.user);
    //        } else {
    //          // If user is expired, also, remove the associated user object from local storage.
    //          this.removeLogonUser();
    //        }
    //      }
    //  });
    //  this.user = this.getLogonUser();
  }

  /**
   * Only call this method one time in the Main App Component's constructor.
   * Note: Before call this init() method, developer need to call "this.appContextService.init();" first.
  */
  // init(callback: (u: User) => void) {
  //   // this.manager = new UserManager(getOidcClientSettings(this));
  //   this.manager.getUser().then(user => {
  //     if (user) {
  //       if (!user.expired) {
  //         this.user = user;
  //         // Refresh local storage's logon user entity.
  //         this.setLogonUser(this.user);
  //       } else {
  //         // If user is expired, also, remove the associated user object from local storage.
  //         this.user = null;
  //         this.removeLogonUser();
  //       }
  //     } else {
  //         this.user = null;
  //         this.removeLogonUser();
  //     }
  //     this.getUserClaims.emit(this.getClaims());
  //     callback(this.user);
  //   });
  //   this.user = this.getLogonUser();
  // }

  /**
   * Try to init Current Logon User info.
   * return login user if user already login or user login successed.
   * If no logon user or user login failed, return null.
   * Reference: https://labs.encoded.io/2016/12/08/asyncawait-with-angular/
   */
  async init(): Promise<User> {
    this.manager = new UserManager(getOidcClientSettings(this));
    //
    // Try to access Identity Server to login user.
    // Wait Async call getUser completed first.
    //
    const user = await this.manager.getUser().catch((ex) => { console.log('getUser error' + ex); });

        if (user) {
          if (!user.expired) {
             this.user = user;
             // Refresh local storage's logon user entity.
             this.setLogonUser(this.user);
             //
             // Populate logon user's info in UI.
             // Such as user name.
             //
             this.getUserClaims.emit(this.getClaims());
            } else {
             // If user is expired, also, remove the associated user object from local storage.
             this.user = null;
             this.removeLogonUser();
          }
        } else {
          this.user = null;
          this.removeLogonUser();
        }
        // console.log('securityoidcservice.init called.', this.user);
        return this.user;
       } // End of init()

  /** Check if user is login. Note: here we will not check user.expired.
   * For Identity server implicit mode, we assume logon user will not expire. */
  isLoggedIn(): boolean {
    return (this.user != null && this.user.access_token !== undefined &&  this.user.access_token.length > 0);
  }

  checkUserRole(role: string): boolean {
    if (this.isLoggedIn()) {
      if (this.user.profile.role) {
        const roles = this.user.profile.role.split(',');
        if (roles.find(x => x === role)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /** Get current logon user's associated claims settings. */
  getClaims(): any {
    console.log('getClaims: ' + JSON.stringify(this.user));
    if (this.isLoggedIn()) {
      return this.user.profile;
    } else {
      return null;
    }
  }

  /**
   * Return logon user's preference culture code (langauge).
   *  If no logon user or logon user no preference language, return default en-ca.
   * return string language code.
   * */
  getUserLanguage(): string {
    if (this.isLoggedIn()) {
      if (this.user.profile.locale) {
        console.log('user locale:' + this.user.profile.locale);
        // const languageKey: keyof typeof Languages = this.user.profile.locale;
        return this.user.profile.locale;
      } else {
        return 'en-us';
      }
    } else {
      return 'en-us';
    }
  }

  /** Return current logon user's Access Token */
  getAuthorizationHeaderValue(): string {
    // return `${this.user.token_type} ${this.user.access_token}`;
    const user = this.getLogonUser();
    if (user) {
      return `${user.token_type} ${user.access_token}`;
    } else {
      return '';
    }
  }

  /** Send request to Identity Server 4 for user login process. */
  login(): Promise<void> {
    console.log('security login called');
    return this.manager.signinRedirect();
  }

  /** Renew token callback. Response for method renewToken. */
  renewTokenCallback() {
    console.log('renewToken callback.');
    this.manager.signinSilentCallback().then((response: any) => { console.log('signinSilentCallback response: ' + response); })
    .catch((err) => {
      console.log('renewToken callback error:' + err);
      this.login();
     });
  }

  /** Renew token. call signinSilent method. */
  renewToken() {
    console.log('renewToken start.');
    return this.manager.signinSilent().then((user: User) => {
      console.log('signinSilent response: ' + JSON.stringify(user));
      if (user && user.access_token && !user.expired) {
          this.setLogonUser(user);
      }
    }).catch((err) => {
      console.log('renewToken error: ' + err);
     });
  }


  /** User sign out. */
  async signout(): Promise<void> {
    // Remove logon user entity from local storage.
    this.removeLogonUser();
    this.removeAppContext();
    // await this.manager.removeUser(); //Don't call this, will break
    await this.manager.revokeAccessToken();
    return this.manager.signoutRedirect();
    // Redirect to idenity server for sign out.
    //  this.manager.signoutRedirect().then(response => {
    //    console.log('signoutredirect done');
    //    // Refresh UI as no user.
    //    this.getUserClaims.emit({ userName: '', firstName: '', lastName: '', isLogin: false }); // Clean up user name.
    //    window.location.replace(this.getCurrentDomain());
    //  });
  }

  /** Get logon user identity from Identity Server 4.
   * Keep logon user in local storage.
   * */
  completeAuthentication(): Promise<void> {
    return this.manager.signinRedirectCallback().then(user => {
      console.log('signinredirectcallback done');
      this.user = user;
      this.getUserClaims.emit(this.getClaims());
      //
      // Keep logon user oidc user object in localstorage.
      //
      this.setLogonUser(this.user);
      this.setAppContextDefaultLanguage(this.getUserLanguage());
    }).catch( ex => {
        //
        // any login exception, redirect back to login again.
        //
        console.log('completeAuthentication exception: ' + ex);
        // this.signout();
    });
  }

  /**
  * Get current logon user object from local storage.
  */
  getLogonUser(): User {
    if (localStorage.getItem(APP_CONSTS.currentUser + this.currentDomain)) {
      return JSON.parse(localStorage.getItem(APP_CONSTS.currentUser + this.currentDomain)) as User;
    } else {
      return null;
    }
  }

  /**
   * Get current App Context from local storage.
   */
  getAppContext(): SJIAppContext {
    // console.log("getAppContext called: " + APP_CONSTS.appContext + this.currentDomain);
    if (localStorage.getItem(APP_CONSTS.appContext + this.currentDomain)) {
      return JSON.parse(localStorage.getItem(APP_CONSTS.appContext + this.currentDomain)) as SJIAppContext;
    } else {
      return null;
    }
  }

  /**
   * Get current accessing company portal's domain.
   * Calling appContextService.getcurrentDomain method.
   */
  getCurrentDomain(): string {
    return this.appContextService.getCurrentDomain();
  }

  /**
   * Get current access portal's associated client Id.
   *  Note: For Admin portal, value = 'admin'.
   *  For company portal, value = company's Id (int primary key of table dbo.Company).
   */
  getClientId(): string {
    return this.appContextService.getClientId();
  }

  /**
  * Get current accessing End User portal's company Id.
  * Note: CompanyId could be different with ClientId.
  */
  getCompanyId(): string {
    return this.appContextService.getCompanyId();
  }

  /** Get selected customer Id from app context. */
  getCustomerId(): string {
    return this.appContextService.getCustomerId();
  }

  /** Get selected ship to Id from app context. */
  getShipToId(): string {
    return this.appContextService.getShipToId();
  }

  /** Get sjiVersion setting. work for auto refresh browser after new deployment. */
  getSJIVersion(): string {
    return this.appContextService.getSJIVersion();
  }

  /** Get current logon user associated currency. CurrencyCd assocaited current company and current selected CompanyCustomer.
   *  If no currency cd setting, return "none".
   */
  getCurrencyCd(): string {
    return this.appContextService.getCurrencyCd();
  }
  //Vendor portal
  getVendorCd(): string {
    return this.appContextService.getVendorCd();
  }

  /**
   * Update current portal's language with logon user's preference language.
   *
   * @param userLanguage logon user's preference langauge. Reference to field "LanguageId" in table dbo.User.
   */
  private setAppContextDefaultLanguage(userLanguage: string) {
    // console.log('setAppContextDefaultLangauge called:' + userLanguage);
    const appContext = this.getAppContext();
    if (appContext) {
      appContext.language = userLanguage;
      this.appContextService.updateAppContext(appContext);
    }
  }

  getAppContextDefaultLanguage(): string {
    // console.log('getAppContextDefaultLangauge called.');
    return this.appContextService.getLanguage();
  }
  /**
  * Save logon user entity into javascript local storage.
  * The reason to keep oidc user object in local storage is UserManager get user process is asynchornous.
  * Which affect some http web api calls happened in service consturctor.
  */
  setLogonUser(user: User) {
    if (user && user.access_token && !user.expired) {
      this.user = user;
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem(APP_CONSTS.currentUser + this.currentDomain, JSON.stringify(user));
    }
  }
  /**
  * Remove logon user entity from javascript local storage.
  */
  removeLogonUser() {
    localStorage.removeItem(APP_CONSTS.currentUser + this.currentDomain);
  }
 /** Remove global appContext storage. */
  removeAppContext() {
    this.appContextService.removeAppContext();
  }

  /** Corporate with oidc manager event. */
 showGoingToExpireDialog() {
  this.messageService.addDialog('Core.GoingToExpire', MessageTypes.Expiration);
 }

 /** Corporate with oidc manager event. redirect to login page.*/
accessTokenExpired() {
  console.log('AccessToken has expired.');
  //
  // Redirect to identity server login page.
  //
  this.login();
}

 /** Corporate with odic manager event. save login user entity to local storage. */
 userLoaded (user: User) {
  this.setLogonUser(user);
 }

 /** Corporate with oid manager event. remove user entity from local storage. */
 userUnloaded () {
   this.removeLogonUser();
 }
}

/** Return settings for Identity Server 4 settings and authorization process setup.
 *  Note: Same settings in IdentityServer project appsettings.json.
 *  Reference: https://www.scottbrady91.com/Angular/SPA-Authentiction-using-OpenID-Connect-Angular-CLI-and-oidc-client
 *  Reference: https://github.com/IdentityModel/oidc-client-js/wiki
 */
export function getOidcClientSettings(securityService: SecurityOidcService): UserManagerSettings {
  console.log('getOidcClientSettings call. Client Id:' + securityService.getClientId());
  return {
    /** Identity Server 4 host url. Note: Point to SJI.IdentityServe project. */
    authority: APP_CONFIGS.IAMUrl, 
    client_id: `${securityService.getClientId()}`,
    /** Current application redirection url when Identity Server authorization process completed successfully. */
    redirect_uri: securityService.getCurrentDomain() + '/assets/oidc/signin-callback.html',
    /** After sign out, current application redirection url.  */
    post_logout_redirect_uri: securityService.getCurrentDomain() + '/',
    /** Settings corporate with Identity Server 4. */
    response_type: 'id_token token',
    /** Settings corporate with Identity Server 4.
     * Note: OneUIWebApi means single page app allow to access OneUIWebApi portal.
     * Note: custom.profile means return additinal custom resources such as custom roles,
     * access rights as claims to client side. contains in token.
     * custom.profile settings in Resources.cs.
     **/
    scope: 'openid profile email SJIWebApi custom.profile',
    filterProtocolClaims: false,
    loadUserInfo: true,
    automaticSilentRenew: APP_CONFIGS.isAccessTokenAutoRenew,
    /** Refresh token redirection url.  */
    silent_redirect_uri: securityService.getCurrentDomain() + '/assets/oidc/silent-callback.html',
    // Number of seconds before the token expires to trigger
    accessTokenExpiringNotificationTime: APP_CONFIGS.accessTokenExpiringNotificationTime, // number of seconds. 120 seconds/2 minutes before access token expire and raise token expiring notification event called "AccessTokenExpiring".
    silentRequestTimeout: 6000, // number of milliseconds to wait for the silent renew to return before assuming it has filed or timeout.
    // will revoke (reference) access tokens at logout time
    revokeAccessTokenOnSignout: true,
    clockSkew: 900 // integer value of seconds. Default value = 300. Work for resolve login infinity looping if client machine datetime is in correct. Set clockSkew is 15 minutes now.
   };
}




