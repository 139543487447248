import { Theme } from '../../models/system/theme';

/**
 * Theme "Dynamaxx". Reference to setting in table dbo.Theme.
 */
export const Theme_Dynamaxx: Theme = {
  name: 'Dynamaxx', // Same as value of field "Code" in table dbo.Theme.
  properties: {
      /* own color */
    '--mainColor': '#000000', // Colour 2
    '--mainButtomColor': '#000000', // main Buttom
    '--minorColor': '#BF1721', // Colour 3
    '--mainColorB':'#000000',
    '--subColor': '#7D120F', // Color 1.
    '--mainBackgroundColor': '#999998', // Background
    '--mainTitleColor':'#f2f4f9',
    '--minorBackgroundColor': '#FFFFFF', // NAV
    '--importantLabel': '#333',
    '--ordinary_label': '#666',
    '--nonImportantLabel': '#999',
    '--dashColor1': '#abb5c4',
    '--dashColor2': '#999998',
    '--controlColor_span1': '#f69125',
    '--controlInptBackgroundColor1': '#f3f2f2',
    '--navTitleColor': '#FFFFFF',
    '--navTitleMinorColor': '#FFFFFF',

    '--extraPriceColor':'#287f3d',    
    '--inTransitColor':'#0435c5',
    '--cartIconColor':'#FFFFFF',  
    '--grid_th_Color': '#F6F6F6',
    '--grid_td_Color': '#FFFFFF',


    /*hearder*/
    '--navbarBackground': '#FFFFFF',
    '--headerClickColor': '#000000',
    '--headerClickColor_border': '#090e2126',

    /*order*/
    '--grid_thColor': '#F6F6F6',
    '--controlColor_order_buttom1': '#f69125',
    '--controlColor_order_buttom2': '#7D120F',
    '--controlColor_order_buttom3': '#090E22',
    '--controlColor_order_buttom4': '#7D120F',
    '--controlColor_buttomBorder1': '#000000',

    /*cart*/
    '--controlColor_buttom2': '#090E22',
    '--controlColor_buttom3': '#C31C28',
    '--controlColor_buttom2_font': '#FFFFFF',
    '--dialogBackgroundColor': '#FFFFFF',

    '--noFillColor':'#474747',
    '--disableCarat':"#ccc",

    /*Direct Container Order Grid */
    '--GridRightBorderTH': '#999998',
    '--GridRightBorderTD': '#CCC',
    '--GridHoverColorTR':'#F09BA2',
    '--TextOnHover': '#FFFFFF'

    /* Bootstrap css override */
   }
};
