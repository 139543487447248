
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { pageModel } from '../../../shared/models/pageModel';

@Component({
  selector: 'comm-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss']
})
export class PagingComponent {
  @Output() onSearch = new EventEmitter<number>();
  public pageList: number[] = [];
  @Input() pageModel: pageModel;
  public pageLastIndex: number = 0;
  public pageTotal = 10;//page reference
  public isExtend = true;
  public isMinus = false;
  // @Input() set PageModel(value: m.pageModel) {
  //   if(value){
  //     this.pageModel = value;
  //     console.log(this.PageModel);
  //     this.initData();
  //   }
  // }

  constructor() {

  }
  public initData() {
    this.pageList = [];
    this.isExtend = true;
    this.isMinus = false;
    if (this.pageModel == null || this.pageModel == undefined) {
      this.pageModel = { pageIndex: 1, pageSize: 40, totalCount: 0 };
    }
    this.pageLastIndex = parseInt(((this.pageModel.totalCount >= this.pageModel.pageSize ? this.pageModel.totalCount - 1 : this.pageModel.totalCount) / this.pageModel.pageSize + 1).toString());
    if (this.pageLastIndex < 1)
      this.pageLastIndex = 1;
    let pageFirstIndex = parseInt(((this.pageModel.pageIndex > 1 ? this.pageModel.pageIndex - 1 : this.pageModel.pageIndex) / this.pageTotal).toString());
    if (pageFirstIndex > 0)
      this.isMinus = true;
    if(this.pageModel.pageIndex > this.pageLastIndex){
      this.pageModel.pageIndex = this.pageLastIndex;
      this.onSearch.emit(this.pageModel.pageIndex);
    }
    for (let index = pageFirstIndex * this.pageTotal + 1; index < (pageFirstIndex + 1) * this.pageTotal + 1; index++) {
      if (index <= this.pageLastIndex) {
        this.pageList.push(index);
        if(index === this.pageLastIndex){
          this.isExtend = false;
          break;
        }
      } else {
        this.isExtend = false;
        break;
      }
    }
  }
  public skip(pageIndex: number) {
    if (pageIndex == this.pageModel.pageIndex)
      return;
    this.pageModel.pageIndex = pageIndex;
    this.initData();
    this.onSearch.emit(pageIndex);
    window.scrollTo(0, 0);
  }
  public replacePageList() {
    let page = this.pageList[this.pageList.length - 1];
    this.pageList = [];
    for (let index = 1; index < this.pageTotal + 1; index++) {
      if (page + index <= this.pageLastIndex) {
        this.pageList.push(page + index);
        this.isMinus = true;
      } else {
        this.isExtend = false;
        break;
      }
    }
  }
  public minusPageList() {
    let page = this.pageList[0];
    this.pageList = [];
    this.isMinus = page > this.pageTotal + 1;
    if (!this.isExtend)
      this.isExtend = true;
    for (let index = page - this.pageTotal; index < page; index++) {
      if (index > 0) {
        this.pageList.push(index);
      }
    }
  }
}
