import { Theme } from '../../models/system/theme';

/**
 * Theme "Ironhead".
 * Reference to setting in table dbo.Theme.
 */
export const Theme_Ironhead: Theme = {
  name: 'Ironhead',
  properties: {
    /* own color */
    '--mainColor': '#09653A', // Color 2
    '--mainButtomColor': '#09653A', // main Buttom
    '--minorColor': '#CB9B41', // Color 3
    '--mainColorB':'#09653A',
    '--subColor': '#26A74F', // Color 1.
    '--mainBackgroundColor': '#8F9B94', // Background
    '--mainTitleColor':'#f2f4f9',
    '--minorBackgroundColor': '#FFFFFF',
    '--importantLabel': '#333',
    '--ordinary_label': '#666',
    '--nonImportantLabel': '#999',
    '--dashColor1': '#abb5c4',
    '--dashColor2': '#8F9B94',
    '--controlColor_span1': '#6c7499',
    '--controlInptBackgroundColor1': '#f3f2f2',
    '--navTitleColor': '#FFFFFF',
    '--navTitleMinorColor': '#FFFFFF',

    '--extraPriceColor':'#287f3d',    
    '--inTransitColor':'#0435c5',
    '--cartIconColor':'#FFFFFF',  
    '--grid_th_Color': '#F6F6F6',
    '--grid_td_Color': '#FFFFFF',


    /*hearder*/
    '--navbarBackground': '#000000',
    '--headerClickColor': '#09653A',
    '--headerClickColor_border': '#090e2126',

     /*order*/
     '--grid_thColor': '#F6F6F6',
    '--controlColor_order_buttom1': '#09653A',
    '--controlColor_order_buttom2': '#26A74F',
    '--controlColor_order_buttom3': '#26A74F',
    '--controlColor_order_buttom4': '#26A74F',
    '--controlColor_buttomBorder1': '#26A74F',

    /*cart*/
    '--controlColor_buttom2': '#090E22',
    '--controlColor_buttom3': '#C31C28',
    '--controlColor_buttom2_font': '#FFFFFF',
    '--dialogBackgroundColor': '#FFFFFF',

    '--noFillColor':'#506358',
    '--disableCarat':"#ccc",

    /*Direct Container Order Grid */
    '--GridRightBorderTH': '#8F9B94',
    '--GridRightBorderTD': '#CCC',
    '--GridHoverColorTR':'#e6ffe6',
    '--TextOnHover': '#FFFFFF'

    /* Bootstrap css override put here.*/
  }
};
