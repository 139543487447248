import { DynamicEnvironment } from "./dynamic-environment";
/**
 *  This file can be replaced during build by using the `fileReplacements` array.
 * `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
 * The list of file replacements can be found in `angular.json`.
 * Reference: https://github.com/angular/angular-cli/blob/master/docs/documentation/stories/application-environments.md
 */
// export const environment = {
//    production: false,
//     /** Enable console.log or not. */
//    enableConsole: true,
//    apiRootUrl: '',
//    IAMUrl : ''

//    // apiRootUrl: 'http://localhost:56693/',
//    // IAMUrl : 'http://localhost:57052/'

//    //apiRootUrl: 'https://b2bsjidemo-webapi.dapasoft.com/',
//    //IAMUrl: 'https://b2bsjidemo-idp.dapasoft.com/'
// };

class Environment extends DynamicEnvironment {
  public production: boolean;
  // public enableConsole: boolean;
  constructor() {
    super();
    this.production = false;
    // this.enableConsole = true;
    //console.log("Environment Created");
  }
}

export const environment = new Environment();

/*
 *In the WebAPI Solution the port are controlled by the following 2 files and they are not in Source Control
 *~\SJI.IdentityServer\Properties\launchSettings.json
 *~\SJI.Web\Properties\launchSettings.json
 */

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
