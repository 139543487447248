import { date } from 'ng4-validators/src/app/date/validator';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { SJIAppContextService } from '../../../core/services/system/sji.appcontext.service';
import { SJIAppContext } from '../../../core/models/system/sjiappcontext';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  // styleUrls: ['./header.component.scss']
})
export class FooterComponent implements OnInit {
  timeZone = '';
  utcDateTime: Date;
  localDateTime: Date;
  dateNew: Date;
  sjiAppContext: SJIAppContext = new SJIAppContext();
  constructor(
    private appContextService: SJIAppContextService
  ) {
    this.sjiAppContext = this.appContextService.getAppContext();
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.utcTime();
    this.dateNew = new Date();
  }

  ngOnInit() {
  }

  utcTime(): void {
    setInterval(() => {
      const today = new Date();
      this.localDateTime = today;
      this.utcDateTime = new Date(today.getTime() + (today.getTimezoneOffset() * 60000));
    }, 1000);
  }
}

