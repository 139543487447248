//import { return  return  environment } from '../../../environments/environment';};
import { APP_CONFIGS } from 'src/app/_configs/appconfigs';

export class SJIApiUrls {
  return
  private get apiMockRootUrl() { return 'http://192.168.1.91:91/mock/sji/' };

  private get apiRootUrl() { return APP_CONFIGS.apiRootUrl; };

  public get identity_user_getclaims() { return this.apiRootUrl + 'api/identity'; };
  public get identity_getcompany() { return this.apiRootUrl + 'api/common/lookup/getcompany'; };
  public get common_lookup_brand() { return this.apiRootUrl + 'api/common/lookup/brand'; };
  public get common_lookup_customershipto() { return this.apiRootUrl + 'api/common/lookup/customershipto'; };
  public get common_lookup_shipmentMethod() { return this.apiRootUrl + 'api/common/lookup/shipmentMethod'; };
  public get common_lookup_category() { return this.apiRootUrl + 'api/common/lookup/category'; };
  public get common_lookup_subcategory() { return this.apiRootUrl + 'api/common/lookup/subcategory'; };
  public get common_lookup_languages() { return this.apiRootUrl + 'api/common/lookup/languages'; };
  /**  api/cart/checkoutwarehouse */
  public get common_checkoutwarehouse() { return this.apiRootUrl + 'api/cart/checkoutwarehouse'; };
  public get common_customer() { return this.apiRootUrl + 'api/common/lookup/customer'; };
  public get common_customershipto() { return this.apiRootUrl + 'api/common/lookup/customershipto'; };
  /** call lookup/CustomerWarehouses */
  public get common_customerwarehouses() { return this.apiRootUrl + 'api/common/lookup/customerwarehouses'; };
  public get common_getmultiple() { return this.apiRootUrl + 'api/common/lookup/getmultiple'; };
  public get common_getcontainertype() { return this.apiRootUrl + 'api/common/lookup/getcontainertype'; };
  public get common_images_showproduct() { return this.apiRootUrl + 'api/common/images/showproduct'; };
  public get common_images_showproductline() { return this.apiRootUrl + 'api/common/images/showproductline'; };
  public get cart_lookup_getcontainertype() { return this.apiRootUrl + 'api/common/lookup/getcontainertype'; };
  public get common_context_initdomain() { return this.apiRootUrl + 'api/common/context/initdomain'; };
  /** Called initAppContext web api method. */
  public get common_context_initappcontext() { return this.apiRootUrl + 'api/common/context/initappcontext'; };
  public get common_context_getnavshiptocode() { return this.apiRootUrl + 'api/common/context/getnavshiptocode'; };
  public get common_company_info() { return this.apiRootUrl + 'api/common/company/getcompanyinfo'; };
  public get common_gettotalcount() { return this.apiRootUrl + 'api/cart/gettotalcount'; };
  public get common_getcustomertheme() { return this.apiRootUrl + 'api/common/context/getcustomertheme'; };
  public get common_vendor() { return this.apiRootUrl + 'api/common/lookup/vendor'; };
  public get common_company_gettextbanner() { return this.apiRootUrl + 'api/common/company/gettextbanner'; };

  public get Order_SearchWareHouse() { return this.apiRootUrl + 'api/order/searchwarehouse'; };
  public get Order_GetWareHouseSettings() { return this.apiRootUrl + 'api/order/getwarehousesettings'; };
  public get Order_GetSizeVariants() { return this.apiRootUrl + 'api/order/getSizeVariants'; };
  // public get Order_Getusersettings() { return this.apiRootUrl + 'api/order/getusersettings';};
  public get order_tracking_search() { return this.apiRootUrl + 'api/tracking/search'; };
  public get order_tracking_phone_order_search() { return this.apiRootUrl + 'api/tracking/searchphoneorders'; };
  public get order_tracking_orderTracking() { return this.apiRootUrl + 'api/tracking/ordertrackingsearch'; };
  public get order_tracking_downloadorderlist() { return this.apiRootUrl + 'api/tracking/downloadorderlist'; };
  public get order_tracking_ordertrackingsearch() { return this.apiRootUrl + 'api/tracking/ordertrackingsearch'; };
  public get order_tracking_addtocart() { return this.apiRootUrl + 'api/tracking/addtocart'; };
  public get order_tracking_printorder() { return this.apiRootUrl + 'api/Report/printorder'; };
  public get order_tracking_showwarehousedetails() { return this.apiRootUrl + 'api/tracking/showwarehousedetails'; };
  public get order_tracking_showdirectcontainerdetails() { return this.apiRootUrl + 'api/tracking/showdirectcontainerdetails'; };
  public get order_tracking_other_order_search() { return this.apiRootUrl + 'api/tracking/searchotherorders' }
  public get order_tracking_detail() { return this.apiRootUrl + 'api/tracking/orderTrackingDetail' }

  public get order_tracking_showwarehousedetailforphoneorders() { return this.apiRootUrl + 'api/tracking/showwarehousedetailforphoneorders'; };
  public get order_tracking_showdirectcontainerdetailforphoneorders() { return this.apiRootUrl + 'api/tracking/showdirectcontainerdetailforphoneorders'; };
  public get order_tracking_ordertrackingforphonedetail() { return this.apiRootUrl + 'api/tracking/ordertrackingforphonedetail'; };

  public get order_tracking_opening_search() { return this.apiRootUrl + 'api/tracking/searchOpeningOrders' }
  public get order_tracking_export_opening() { return this.apiRootUrl + 'api/tracking/exportOpeningOrders' }

  public get order_getlinedetails() { return this.apiRootUrl + 'api/order/getlinedetails'; };
  public get order_getdclinedetails() { return this.apiRootUrl + 'api/order/getdclinedetails'; };
  /** Called order/addwarehouse web api */
  public get order_addwarehouse() { return this.apiRootUrl + 'api/order/addwarehouse'; };
  public get order_adddirectcontainer() { return this.apiRootUrl + 'api/order/adddirectcontainer'; };
  public get order_searchdirectcontainer() { return this.apiRootUrl + 'api/order/searchdirectcontainer'; };
  public get order_getproductdetails() { return this.apiRootUrl + 'api/order/getproductdetails'; };
  public get order_getWareHouseItemNetPrice() { return this.apiRootUrl + 'api/order/getWareHouseItemNetPrice'; };

  public get order_searchcontainer() { return this.apiRootUrl + 'api/order/searchcontainer'; };
  /** Disabled. api/order/getbrandproductlinebyshipto */
  public get order_getbrandproductlinebyshipto() { return this.apiRootUrl + 'api/order/getbrandproductlinebyshipto'; };
  public get order_GetItemCategoriesByShipto() { return this.apiRootUrl + 'api/order/getitemcategoriesbyshipto'; };
  public get order_GetItemBrandsByShiptoAndItemCategory() { return this.apiRootUrl + 'api/order/getitembrandsbyshiptoanditemcategory'; };
  public get order_GetProductLinesByShiptoAndItemCategoryAndItemBrand() { return this.apiRootUrl + 'api/order/getproductlinesbyshiptoanditemcategoryanditembrand'; };

  /** Web api for factory direct price sheet report. */
  public get order_generatefactorydirectpricesheet() { return this.apiRootUrl + 'api/Report/generatefactoryypricesheetfromnav'; };
  /** Web api for ware house price sheet report. */
  public get order_generatewarehousepricesheet() { return this.apiRootUrl + 'api/Report/generatewarehousepricesheetfromnav'; };
  public get order_generatefactorypricesheetmaxamjob() { return this.apiRootUrl + 'api/Report/generatefactorypricesheetmaxamjob'; };
  public get order_generatewarehousepricesheetmaxamjob() { return this.apiRootUrl + 'api/Report/generatewarehousepricesheetmaxamjob'; };
  public get order_gettemplatelist() { return this.apiRootUrl + 'api/order/gettemplatelist'; };
  public get order_addbytemplate() { return this.apiRootUrl + 'api/order/addbytemplate'; };
  public get order_downloadcontainertemplate() { return this.apiRootUrl + 'api/order/exportdirectcontainertemplate'; };
  public get order_importordersheet() { return this.apiRootUrl + 'api/order/importordersheet'; };
  public get order_getreportingpricesheetjoblist() { return this.apiRootUrl + 'api/Report/getreportingpricesheetjoblist'; };
  public get order_getMaxamJobReport() { return this.apiRootUrl + 'api/Report/getMaxamJobReport'; };

  public get cart_GetwarehousecheckoutInfo() { return this.apiRootUrl + 'api/cart/getwarehousecheckoutInfo'; };
  public get cart_Getdata() { return this.apiRootUrl + 'api/cart/getdata'; };
  public get cart_emptyCart() { return this.apiRootUrl + 'api/cart/emptyCart'; };
  public get cart_removeitem() { return this.apiRootUrl + 'api/cart/removeitem'; };
  public get cart_updateitem() { return this.apiRootUrl + 'api/cart/updateitem'; };
  public get cart_updatecontainertype() { return this.apiRootUrl + 'api/cart/updatecontainertype'; };
  public get cart_changeshipto() { return this.apiRootUrl + 'api/cart/changeshipto'; };
  public get cart_savetemplate() { return this.apiRootUrl + 'api/cart/savetemplate'; };
  public get cart_topUp() { return this.apiRootUrl + 'api/cart/topUp'; };
  public get cart_checkoutdirectcontainer() { return this.apiRootUrl + 'api/cart/checkoutdirectcontainer'; };
  public get cart_placeorderwarehouse() { return this.apiRootUrl + 'api/cart/placeorderwarehouse'; };
  public get cart_placeorderdirectcontainer() { return this.apiRootUrl + 'api/cart/placeorderdirectcontainer'; };

  public get cart_survey() { return this.apiRootUrl + 'api/cart/survey'; };
  public get cart_getcartinfobands() { return this.apiRootUrl + 'api/cart/getcartinfobands'; };

  public get cart_getwarehousegroups() { return this.apiRootUrl + 'api/cart/getwarehousegroups'; };
  public get cart_getdirectcontainegroups() { return this.apiRootUrl + 'api/cart/getdirectcontainegroups'; };



  public get claims_GetWarrantyHistory() { return this.apiRootUrl + 'api/claim/GetWarrantyClaimHistory'; };
  public get claims_GetInitDataSet() { return this.apiRootUrl + 'api/claim/GetInitDataSet/'; };
  public get claims_GetModelTypeByBrand() { return this.apiRootUrl + 'api/claim/GetModelTypeByBrand/'; };
  public get claims_GetItemByBrandAndModelType() { return this.apiRootUrl + 'api/claim/GetItemByBrandAndModelType/'; };
  public get claims_UploadFile() { return this.apiRootUrl + 'api/claim/UploadFile/'; };
  public get claims_SaveWarrantyClaim() { return this.apiRootUrl + 'api/claim/SaveWarrantyClaim'; };
  public get claims_SearchCustomer() { return this.apiRootUrl + 'api/claim/SearchCustomer/'; };
  public get claims_GetDataSetByCustomerCd() { return this.apiRootUrl + 'api/claim/GetDataSetByCustomerCd/'; };
  public get claims_GetItemByBrandCd() { return this.apiRootUrl + 'api/claim/GetItemByBrandCd/'; };
  public get claims_GetServiceItemByCategoryCd() { return this.apiRootUrl + 'api/claim/GetServiceItemByCategoryCd/'; };
  public get claims_SaveNationalClaim() { return this.apiRootUrl + 'api/claim/SaveNationalClaim'; };
  public get claims_DownloadWarrantyClaimPdf() { return this.apiRootUrl + 'api/claim/DownloadWarrantyClaimPdf/'; };
  public get claims_GetCustomerShipToAddressByCustomerCd() { return this.apiRootUrl + 'api/claim/GetCustomerShipToAddressByCustomerCd/'; };
  public get claims_GetMachineTypesByBrandCd() { return this.apiRootUrl + 'api/claim/GetMachineTypesByBrandCd/'; };
  public get claims_GetMachineModelByBrandCdAndTypeCd() { return this.apiRootUrl + 'api/claim/GetMachineModelByBrandCdAndTypeCd/'; };
  public get claims_GetMachineRims() { return this.apiRootUrl + 'api/claim/GetMachineRims/'; };


  public get maxam_claims_GetMachineBrand() { return this.apiRootUrl + 'api/maxamclaim/GetMachineBrand'; };
  public get maxam_claims_GetDealerByShipTo() { return this.apiRootUrl + 'api/maxamclaim/GetDealerByShipTo'; };
  public get maxam_claims_GetDataSetByShipTo() { return this.apiRootUrl + 'api/maxamclaim/GetDataSetByShipTo'; };
  public get maxam_claims_GetMachineTypesByBrandCd() { return this.apiRootUrl + 'api/maxamclaim/GetMachineTypesByBrandCd/'; };
  public get maxam_claims_GetMachineModelByBrandCdAndTypeCd() { return this.apiRootUrl + 'api/maxamclaim/GetMachineModelByBrandCdAndTypeCd/'; };
  public get maxam_claims_SearchCustomer() { return this.apiRootUrl + 'api/maxamclaim/SearchCustomer/'; };
  public get maxam_claims_SearchDealerCustomer() { return this.apiRootUrl + 'api/maxamclaim/SearchDealerCustomer/'; };
  public get maxam_claims_SearchMaxamInvoice() { return this.apiRootUrl + 'api/maxamclaim/SearchMaxamInvoice/'; };
  public get maxam_claims_GetOrderList() { return this.apiRootUrl + 'api/maxamclaim/GetOrderList/'; };
  public get maxam_claims_SaveNationalClaim() { return this.apiRootUrl + 'api/maxamclaim/SaveNationalClaim'; };
  public get maxam_claims_UploadFile() { return this.apiRootUrl + 'api/maxamclaim/UploadFile/'; };


  public get documentcenter_salesorder() { return this.apiRootUrl + 'api/documentcenter/salesorder'; };
  public get documentcenter_salesorderwh() { return this.apiRootUrl + 'api/documentcenter/salesorderwh'; };
  public get documentcenter_proformadetails() { return this.apiRootUrl + 'api/documentcenter/proformadetails'; };
  /** getproformareport call report service. Note: This call has been replaced by documentcenter_getvarioussalesreport call. */
  public get documentcenter_downloadproforma() { return this.apiRootUrl + 'api/Report/getproformareport'; };
  public get documentcenter_shipmentdetails() { return this.apiRootUrl + 'api/documentcenter/shipmentdetails'; };
  public get documentcenter_shipmentcontainertrack() { return this.apiRootUrl + 'api/documentcenter/shipmentcontainertrack'; };
  /**  getshipmentreport call report service. */
  public get documentcenter_downloadshipment() { return this.apiRootUrl + 'api/Report/getshipmentreport'; };
  public get documentcenter_shipmentpage() { return this.apiRootUrl + 'api/documentcenter/shipment'; };
  public get documentcenter_invoiceandcreditmemo() { return this.apiRootUrl + 'api/documentcenter/invoiceandcreditmemo'; };
  public get documentcenter_incomingshipmentreport() { return this.apiRootUrl + 'api/documentcenter/incomingshipmentreport'; };
  public get documentcenter_downloadincomingshipmentfile() { return this.apiRootUrl + 'api/documentcenter/downloadincomingshipmentfile'; };
  /**  getinvoiceallreport call report service. */
  public get documentcenter_invoiceandcreditmemoreport() { return this.apiRootUrl + 'api/Report/getinvoiceallreport'; };
  /**
   * Call GetVariousSalesReport to connect Navision Document WCF service to render invoice or credit memo or shippment report pdf.
   * Baesd on input parameter number data format to decide rednering which kind of report.
   * number() { return "INV***" is rendering "Sales Invoice Report".
   * number() { return "CR***" is rendering "Sales Credit Memo Report".
  *  number() { return "SH***" is rendering "Sales Shippment Report".
  *  number() { return "SO***" is rendering "Sales Order Report".
  */
  public get documentcenter_getvarioussalesreport() { return this.apiRootUrl + 'api/Report/getvarioussalesreport'; };

  /** GetCustomerStatementReport call reprot service. */
  public get documentcenter_monthlystatements() { return this.apiRootUrl + 'api/Report/getcustomerstatementreport'; };
  public get documentcenter_getincomingshipmentreport() { return this.apiRootUrl + 'api/Report/getincomingshipmentreport'; };

  public get itemPromotion_getItemPromotion() { return this.apiRootUrl + 'api/itemPromotion/getItemPromotion'; };
  public get admin_showImage() { return this.apiRootUrl + 'api/admin/OrderPromotionManagement/showImage'; };

  public get myProfile_getMyProfile() { return this.apiRootUrl + 'api/myProfile/getMyProfile'; };
  public get myProfile_getOrderTemplateDetail() { return this.apiRootUrl + 'api/myProfile/getOrderTemplateDetail'; };
  public get myProfile_saveSetting() { return this.apiRootUrl + 'api/myProfile/saveSetting'; };
  public get myProfile_saveUser() { return this.apiRootUrl + 'api/myProfile/saveUser'; };
  public get myProfile_saveShipTo() { return this.apiRootUrl + 'api/myProfile/saveShipTo'; };
  public get myProfile_delteOrderTemplate() { return this.apiRootUrl + 'api/myProfile/delteOrderTemplate'; };

  public get myProfile_resetPassword() { return this.apiRootUrl + 'api/myProfile/saveNewPassword'; };

  public get catalog_getcatalogdata() { return this.apiRootUrl + 'api/catalog/getcatalogdata'; };
  public get catalog_downloadfile() { return this.apiRootUrl + 'api/catalog/downloadfile'; };

  public get purchase_searchpurchaseorder() { return this.apiRootUrl + 'api/purchase/searchpurchaseorder'; };
  public get purchase_purchaseorderdetails() { return this.apiRootUrl + 'api/purchase/purchaseorderdetails'; };
  public get purchase_searchvendorincomingshipment() { return this.apiRootUrl + 'api/purchase/searchvendorincomingshipment'; };
  public get purchase_vendorincomingshipmentdetails() { return this.apiRootUrl + 'api/purchase/vendorincomingshipmentdetails'; };

  public get vendor_shipment_saveshipment() { return this.apiRootUrl + 'api/vendor/shipment/saveshipment'; };
  public get vendor_shipment_getdata() { return this.apiRootUrl + 'api/vendor/shipment/getdata'; };
  public get vendor_shipment_searchpurchaseheader() { return this.apiRootUrl + 'api/vendor/shipment/searchpurchaseheader'; };
  public get vendor_shipment_getshipmentlines() { return this.apiRootUrl + 'api/vendor/shipment/getshipmentlines'; };
  public get vendor_shipment_getshipmentdraftlist() { return this.apiRootUrl + 'api/vendor/shipment/getshipmentdraftlist'; };
  public get vendor_shipment_deleteshipmentdraft() { return this.apiRootUrl + 'api/vendor/shipment/deleteshipmentdraft'; };
  public get vendor_invoiceandcreditmemo() { return this.apiRootUrl + 'api/vendor/invoiceAndCreditMemo/search'; };
  public get vendor_opencontainer_searchopencontainer() { return this.apiRootUrl + 'api/vendor/opencontainer/searchopencontainer'; };
  public get vendor_opencontainer_opencontainerdetails() { return this.apiRootUrl + 'api/vendor/opencontainer/opencontainerdetails'; };
  public get vendor_opencontainer_getdetailmodificationlist() { return this.apiRootUrl + 'api/vendor/opencontainer/getdetailmodificationlist'; };
  public get vendor_opencontainer_saveopencontainer() { return this.apiRootUrl + 'api/vendor/opencontainer/saveopencontainer'; };

  public get track_searchTracks() { return this.apiRootUrl + 'api/track/searchTracks'; };
  public get track_getLooks() { return this.apiRootUrl + 'api/track/getLooks'; };
}

export const SJI_API_URLS = new SJIApiUrls();
