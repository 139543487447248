import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { SJI_API_URLS } from '../_configs/webapiurls';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { SJIAppContextService } from '../../core/services/system/sji.appcontext.service';

@Injectable()
export class SJIService {

     /**
     * When add/remove item from cart, system should raise this event.
     * Top bar menu needs to listen this event and refresh cart info after cart items modified.
     * It is public event.
     */
    public cartChanged = new EventEmitter<string>();

  constructor(private http: HttpClient,private sjiAppContextService:SJIAppContextService) {
  }
  warehouseCartInfoList: any = [];
  warehouseCartInfo: any = { scale: 0, warehouseTotalAmount: 0, warehouseTotalCount: 0, warehouseTotalWeight: 0 };
  // backOrderCartInfoList: any = [];
  // backOrderCartInfo: any = { scale: 0, warehouseTotalAmount: 0, warehouseTotalCount: 0, warehouseTotalWeight: 0 };
  containerCartInfoList: any = [];
  containerCartInfo: any = { containerTotalAmount: 0, numberOfContainers: 0, scale: 0 };
  companyCustomerShippingAddressCd:string=null;

  /**
   * Count current logon user's associated warehouse shopping cart information.
   */
  common_gettotalcount() {
    if(this.companyCustomerShippingAddressCd==null){
      let sjiAppContext = this.sjiAppContextService.getAppContext();
      this.companyCustomerShippingAddressCd=sjiAppContext.companyCustomerShippingAddressCd;
    }
    this.get(SJI_API_URLS.common_gettotalcount).subscribe(data => {
      //if (data == null || data == undefined) return;
      //console.log(data);
      let result = data as any[];

      this.warehouseCartInfoList = result.filter(m => m.isWarehouse == true);
      if (this.warehouseCartInfoList != null && this.warehouseCartInfoList.length > 0){
        let data=this.warehouseCartInfoList.filter(m=>m.shipToId==this.companyCustomerShippingAddressCd);
        this.warehouseCartInfo={ scale: 0, warehouseTotalAmount: 0, warehouseTotalCount: 0, warehouseTotalWeight: 0 };
        if(this.companyCustomerShippingAddressCd!=null&&data.length>0){
          for (let index = 0; index < data.length; index++) {
            this.warehouseCartInfo.scale += data[index].scale;
            this.warehouseCartInfo.warehouseTotalAmount += data[index].warehouseTotalAmount;
            this.warehouseCartInfo.warehouseTotalCount += data[index].warehouseTotalCount;
            this.warehouseCartInfo.warehouseTotalWeight += data[index].warehouseTotalWeight;
          }
          //this.warehouseCartInfo=data[0];
        }else{
          this.warehouseCartInfo = { warehouseTotalAmount: 0, numberOfContainers: 0, scale: 0 };
        }

      }else
        this.warehouseCartInfo = { scale: 0, warehouseTotalAmount: 0, warehouseTotalCount: 0, warehouseTotalWeight: 0 };
      this.containerCartInfoList = result.filter(m => m.isWarehouse == false);
      if (this.containerCartInfoList != null && this.containerCartInfoList.length > 0){
        let data=this.containerCartInfoList.filter(m=>m.shipToId==this.companyCustomerShippingAddressCd);
        if(this.companyCustomerShippingAddressCd!=null&&data.length>0){
          this.containerCartInfo=data[0];
        }else{
          this.containerCartInfo = { containerTotalAmount: 0, numberOfContainers: 0, scale: 0 };
        }
      }
      else
        this.containerCartInfo = { containerTotalAmount: 0, numberOfContainers: 0, scale: 0 };
      // this.backOrderCartInfoList = result.filter(m => m.IsBackOrder == true);
      // if (this.backOrderCartInfoList != null && this.backOrderCartInfoList.length > 0)
      //   this.backOrderCartInfo = this.backOrderCartInfoList[0];
      // else
      //   this.backOrderCartInfo = { scale: 0, warehouseTotalAmount: 0, warehouseTotalCount: 0, warehouseTotalWeight: 0 };
      console.log(result);
    });
  }
  get(url: string, params?: any) {
    return this.http.get(url,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        params: params
      }
    );
  }
  post(url, data) {
    return this.http.post(url, data,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    );
  }
  cloneObject<T>(obj: T): T {
    let copy: T = JSON.parse(JSON.stringify(obj)) as T;
    return copy;
  }
  postDownload(url, params) {
    return this.http.post(url, params, { responseType: 'blob' });
  }

  /**
   *  Call this method to raise "cartChanged" event to global.
   *  let other components know cart info need to refresh.
   */
  raiseCartChangedEvent() {
    console.log('raiseCartChangedEvent');
    this.cartChanged.emit();
  }

  /**
   *  Format the input datetime to be UTC time zone datetime format.
   *  Note: Here, It will not modify the datetime value. It only simply keep the same datetime value, but format it as timezone "0".
   *  The scenario is: what client input in the Form, system always assume it is datetime value for UTC datetime.
   *  And client's input datetime will be directly saved into database without convert it to UTC datetime value.
   *  And we always assume the datetime value saved in database is UTC datetime.
   *
   * */
  public theCorrectTimeZone(d: Date): Date {
    if (d) {
      console.log(d);
      const year = d.getFullYear();
      const month = d.getMonth();
      const date = d.getDate();
      const hour = d.getHours();
      const minute = d.getMinutes();
      const second = d.getSeconds();
      const str = year + '-' + this.padding_left(String(month + 1), '0', 2) + '-' + this.padding_left(String(date), '0', 2)
        + 'T' + this.padding_left(String(hour), '0', 2) + ':' + this.padding_left(String(minute), '0', 2)
        + ':' + this.padding_left(String(second), '0', 2)
        + '.000Z';

      return JSON.parse('"' + str + '"');
    } else {
      return null;
    }
  }

  private padding_left(s, c, n) {
    if (!s || !c || s.length >= n) {
      return s;
    }
    var max = (n - s.length) / c.length;
    for (var i = 0; i < max; i++) {
      s = c + s;
    }
    return s;
  }
}
