import { SecurityOidcService } from '../services/system/security.oidc.service';
import {Injectable} from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

/**
 * Any http request send out, application auto includes current login user's oauth2 token in the http request's header.
 * Further, application includes current running language's culture code in the http request's header.
 * Work for server side authentication and multiple languages support.
 * Assess token got from Identity Server 4.
 */
@Injectable()
export class SecurityOidcInterceptor implements HttpInterceptor {
    /**  It is value of hours offset for timezone. negative value. for example value = -5 for Eastern Standard Time to UTC. */
    timeZoneOffset: string;
    constructor(private securityService: SecurityOidcService) {
      this.timeZoneOffset = String(-(new Date()).getTimezoneOffset() / 60);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     //
     // Add authorization header with jwt token if available.
     //
        //
        // If there is logon user, check logon user's prefer language.
        // Corporate with RequestAppContextMiddleware.cs in server side.
        //
        if (this.securityService.isLoggedIn()) {
          // console.log('oidc interceptor:' + this.securityService.getAppContextDefaultLanguage());
          request = request.clone({
             // tslint:disable-next-line:max-line-length
             setHeaders: {
               authorization: this.securityService.getAuthorizationHeaderValue(),
               currentLanguage: this.securityService.getAppContextDefaultLanguage(),
               // clientId: this.securityService.getClientId(),
               companyId: this.securityService.getCompanyId(),
               customerId: this.securityService.getCustomerId(),
               shipToId: this.securityService.getShipToId(),
               timeZoneOffset: this.timeZoneOffset,
               sjiVersion: this.securityService.getSJIVersion(),
               currencyCd: this.securityService.getCurrencyCd(),
               vendorCd: this.securityService.getVendorCd()
              }
          });
        } else {
           // If there is no logon user, system still need to pass default langauge as culture code back to server side.
           request = request.clone({
              setHeaders: {
                currentLanguage: this.securityService.getAppContextDefaultLanguage(),
                // clientId: this.securityService.getClientId(),
                companyId: this.securityService.getCompanyId(),
                customerId: this.securityService.getVendorCd(),
                shipToId: this.securityService.getShipToId(),
                timeZoneOffset: this.timeZoneOffset,
                sjiVersion: this.securityService.getSJIVersion(),
                currencyCd: this.securityService.getCurrencyCd(),
                vendorCd: this.securityService.getVendorCd()
              }
           });
        }

     return next.handle(request).do((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Do stuff with response if you want.
        }
      }, (err: any) => {
        if ( err instanceof HttpErrorResponse ) {
          if ( err.status === 401 ) {
              // this.securityService.login();
              // Note: Now, HttpErrorInterceptor  take care the error.
          }
        }
      });
   }
}

/**
 * Provider POJO for the oidc interceptor
 */
export const SecurityOidcInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: SecurityOidcInterceptor,
  multi: true
};
