import { Languages } from './../../../_enumerations/languages';
import { APP_CONSTS } from './../../../_configs/consts';
import { SJI_API_URLS } from './../../../sji/_configs/webapiurls';
import { SJIAppContext } from './../../models/system/sjiappcontext';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject, EventEmitter } from '@angular/core';
import { ThemingService } from '../../theming';
import { DOCUMENT } from '@angular/platform-browser';

/**
 * Work for handle current running application's shared information.
 * Note: This is a shared service across the whole application.
 * It is a  singleton services.
 * Reference:
 * https://angular.io/guide/singleton-services
 * https://stackoverflow.com/questions/36158848/what-is-the-best-way-to-declare-a-global-variable-in-angular-2-typescript/36183988
 */
@Injectable(
    {
        /**
          * Define SJIAppContextService as a shared service.
          * Angular creates a single, shared instance of the service and injects into any class that asks for it.
          * Reference: http://www.talkingdotnet.com/providing-shared-instance-of-service-in-angular-6/
          */
        providedIn: 'root',
    }
)
export class SJIAppContextService {
    private currentDomain = '';
    /**
     * Emit the CompanyCustomerCd when selected Customer from the drop down on the top menu.
     * public event.
     */
    public customerChanged = new EventEmitter<string>();
    /**
     * Emit the CompanyCustomerShippingAddressCd when selected Customer Ship To from the drop down on top menu.
     * public event.
     */
    public customerShipToChanged = new EventEmitter<string>();
    public isInitVersionChange: boolean = false;

    public languageChanged = new EventEmitter<string>();

    appContext: SJIAppContext;

    constructor(private http: HttpClient, private themeServie: ThemingService, @Inject(DOCUMENT) private document) {
        this.currentDomain = this.getCurrentDomain();
        this.appContext = this.getAppContext();
        if (!this.appContext) {
            this.appContext = new SJIAppContext();
        }
        this.appContext.domain = this.currentDomain;
    }

    /**
     *  Only need to call initDomain() method one time in the main app component's constructor.
    */
    async initDomain() {
        this.getCurrentDomain();
        const response: any = await this.http.get<SJIAppContext>(SJI_API_URLS.common_context_initdomain,
            { params: { domain: this.appContext.domain } }).toPromise();
        console.log('Called initDomain response: ' + JSON.stringify(response));
        if (response && response.isSuccess) {
            this.appContext.companyId = response.companyId;
            this.appContext.companyTheme = response.companyTheme;
            this.appContext.isAdminPortal = response.isAdminPortal;
            this.appContext.companyName = response.companyName;
            this.appContext.mobileWebsite= response.mobileWebsite;
            this.updateAppContext(this.appContext);
        } else {
            this.removeAppContext();
        }
    }

    /**
     *  Only need to call initContext() method one time in the main app component's constructor after init securityService.
    */
    async initContext() {
        const response = await this.http.post<SJIAppContext>(
            SJI_API_URLS.common_context_initappcontext + '?domain=' + this.appContext.domain,
            {}).toPromise();

            console.log('Called initContext response: ' + JSON.stringify(response));

        if (response) {
            this.appContext.companyId = response.companyId;
            this.appContext.companyTheme = response.companyTheme;
            this.appContext.isAdminPortal = response.isAdminPortal;
            this.appContext.modules = response.modules;
            this.appContext.isAccessDocInvoice = response.isAccessDocInvoice;
            this.appContext.isAccessDocMonthlyStatement = response.isAccessDocMonthlyStatement;
            this.appContext.isIncomingShipmentReport = response.isIncomingShipmentReport;
            this.appContext.isAccessDocSalesOrder = response.isAccessDocSalesOrder;
            this.appContext.isAccessDocSalesOrderWh = response.isAccessDocSalesOrder;
            this.appContext.isAccessDocShipment = response.isAccessDocShipment;
            this.appContext.defaultOrderType = response.defaultOrderType;
            this.appContext.companyCustomerTheme = response.companyCustomerTheme;
            this.appContext.isTempPasswordLogin = response.isTempPasswordLogin;
            this.appContext.language = response.language;
            this.appContext.isDisableOrdering = response.isDisableOrdering;
            this.appContext.isListPriceOnly = response.isListPriceOnly;
            this.appContext.isHideDcPrice = response.isHideDcPrice;
            this.appContext.currencyCd = response.currencyCd;
            this.appContext.displayPhone = response.displayPhone;
            this.appContext.displayEmail = response.displayEmail;
            this.appContext.displayFax = response.displayFax;
            this.appContext.fetLiable = response.fetLiable;
            this.appContext.navCountyCode = response.navCountyCode;
            this.appContext.hasTsRate = response.hasTsRate;
            this.appContext.itemsPerPage = response.itemsPerPage;
            this.appContext.isWebServicePrice = response.isWebServicePrice;
            this.appContext.enableRefurbSearch = response.enableRefurbSearch;
            this.appContext.freightSurchargeEnabled = response.freightSurchargeEnabled;
            this.appContext.vendorCd = response.vendorCd;

            if (response.companyCustomerCd) {
                this.appContext.companyCustomerCd = response.companyCustomerCd;
                this.appContext.navCustomerCd = response.navCustomerCd;
            }
            if (response.companyCustomerShippingAddressCd) {
                this.appContext.companyCustomerShippingAddressCd = response.companyCustomerShippingAddressCd;
                this.appContext.navShipToCode = response.navShipToCode;
            }
            if(this.appContext.sjiVersion == null || this.appContext.sjiVersion === response.sjiVersion){
                this.appContext.sjiVersion = response.sjiVersion;
            }else{
                this.isInitVersionChange = true;
            }

            this.updateAppContext(this.appContext);
        } else {
            this.removeAppContext();
        }
    }

    /**
     * Get current accessing company portal's information from local storage.
     */
    getAppContext(): SJIAppContext {
        if (localStorage.getItem(APP_CONSTS.appContext + this.currentDomain)) {
            return JSON.parse(localStorage.getItem(APP_CONSTS.appContext + this.currentDomain)) as SJIAppContext;
        } else {
            return null;
        }
    }

    /**
     * Get current access portal's associated client Id.
     *  Note: For Admin portal, value = 'admin'.
     *  For company portal, value = company's Id (int primary key of table dbo.Company).
     */
    getClientId() {
        const appContext = this.getAppContext();
        if (appContext) {
            if (appContext.isAdminPortal) {
                return 'admin';
            } else {
                console.log('getClientId: ' + appContext.companyId);
                return appContext.companyId;
            }
        } else {
            return '';
        }
    }

    /**
     * Get current accessing End User portal's company Id.
     * Note: CompanyId could be different with ClientId.
     */
    getCompanyId() {
        const appContext = this.getAppContext();
        if (appContext) {
            return appContext.companyId;
        } else {
            return '';
        }
    }

    getCustomerId() {
        const appContext = this.getAppContext();
        if (appContext && appContext.companyCustomerCd) {
            return appContext.companyCustomerCd;
        } else {
            return '';
        }
    }
    getVendorCd() {
        const appContext = this.getAppContext();
        if (appContext && appContext.vendorCd) {
            return appContext.vendorCd;
        } else {
            return '';
        }
    }

    getNavCustomerId() {
        let code = this.getCustomerId();
        if (code.indexOf('_') > 0) {
            code = code.substr(0, code.indexOf('_'));
        }
        return code;
    }

    /**
     * Return current processing CompanyCustomerShippingAddressCd.
     */
    getShipToId() {
        const appContext = this.getAppContext();
        if (appContext && appContext.companyCustomerShippingAddressCd) {
            return appContext.companyCustomerShippingAddressCd;
        } else {
            return '';
        }
    }

    /** Get sjiVersion setting. work for auto refresh browser after new deployment. */
    getSJIVersion(): string {
      const appContext = this.getAppContext();
      if (appContext && appContext.sjiVersion != null && appContext.sjiVersion.length > 0) {
          return appContext.sjiVersion;
      } else {
         return '1';
      }
    }

    /**
     *  Get current logon user associated currency. CurrencyCd assocaited current company and current selected CompanyCustomer.
     *  Note: Default value = 'none'.
     */
    getCurrencyCd(): string {
        const appContext = this.getAppContext();
        if (appContext && appContext.sjiVersion != null && appContext.sjiVersion.length > 0) {
            return appContext.currencyCd;
        } else {
           return 'none';
        }
    }

    /**
     * Get current accessing portal's rendering language.
     */
    getLanguage(): string {
        const appContext = this.getAppContext();
        if (appContext != null && appContext.language) {
            if (appContext.language === 'ZH' || appContext.language === '4') {
                return Languages.Chinese_CHS;
            }
            if (appContext.language === 'ES' || appContext.language === '3') {
                return Languages.Spanish_US;
            }
            if (appContext.language === 'FR' || appContext.language === '2') {
                return Languages.French_CA;
            }
        }
        return Languages.English_US;
    }

    getLanguageWithId(id:number) {
       switch(id) {
        case 1: return Languages.English_US;
        case 2: return Languages.French_CA;
        case 3: return Languages.Spanish_US;
        case 4: return Languages.Chinese_CHS;
        default: return Languages.English_US;
       }
    }

    getLanguageId(): any {
        const appContext = this.getAppContext();
        if (appContext != null && appContext.language) {
            if (appContext.language === 'ZH' || appContext.language === '4') {
                return 4;
            }
            if (appContext.language === 'ES' || appContext.language === '3') {
                return 3;
            }
            if (appContext.language === 'FR' || appContext.language === '2') {
                return 2;
            }
        }
        return 1;
    }

    /**
     * Save current accessing company portal's app context information into local storage.
     *
     */
    updateAppContext(inputAppContext: SJIAppContext) {
        if (inputAppContext) {
            localStorage.setItem(APP_CONSTS.appContext + this.currentDomain, JSON.stringify(inputAppContext));
            this.appContext = inputAppContext;
        }
    }

    /**
     * Remove current accessing company portal's associated info from local storage.
     */
    removeAppContext() {
        localStorage.removeItem(APP_CONSTS.appContext + this.currentDomain);
    }

    getCurrentDomain() {
        let result = '';
        if (document.location.port.length === 0) {
            // Return domain without port. Note: Port = 80 as default.
            result = document.location.protocol + '//' + document.location.hostname;
        } else {
            // Return domain with port. Note: Port != 80 in this case.
            result = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
        }
        return result;
    }

    /**
     * When selected one Customer from the drop down, system needs to check if the selected customer has itself theme,
     * setting is in field 'ThemeId' in table dbo.CompanyCustomer.
     * If customer has theme, need to refresh UI with the defined theme.
     * return themeCode value of the specified customer.
     */
    async setThemeBasedOnCompanyCustomer(companyCustomerCd: string): Promise<string> {
        let result = 'Default'; // Default theme code.

        // console.log('appCotext CustomerCd:' + this.appContext.companyCustomerCd + ' selected customer: ' + companyCustomerCd);
        if (this.appContext.companyCustomerCd !== companyCustomerCd) {
            // check customer if it has customized theme.
            const themeCode = await this.http.get<string>(SJI_API_URLS.common_getcustomertheme,
                { params: { companyCustomerCd: companyCustomerCd } }).toPromise();

            //
            // Code field in table dbo.Theme.
            //
            if (themeCode) {
                result = themeCode;
                this.themeServie.setTheme(themeCode);
            } else {
                this.themeServie.setTheme(this.appContext.companyTheme);
            }
        }

        return result;
    }

    /**
     * Keep current selected company customer and its associated theme code and compnay customer shipping address cd into appContext.
     * @param companyCustomerCd : current selected company customer from drop down.
     * @param companyCustomerTheme: theme code associated to specified company customer.
     * @param companyCustomerShippingAddressCd current selected customer's associated selected shipping address.
     */
    updateSelectedCompanyCustomer(companyCustomerCd: string, companyCustomerTheme: string, companyCustomerShippingAddressCd: string) {
        console.log('updateSelectedCompanyCustomer called ' + companyCustomerCd + ' ' + companyCustomerTheme);
        this.appContext = this.getAppContext();
        if (this.appContext && this.appContext.companyCustomerCd !== companyCustomerCd) {
            this.appContext.companyCustomerCd = companyCustomerCd;
            this.appContext.companyCustomerTheme = companyCustomerTheme;
            this.appContext.companyCustomerShippingAddressCd = companyCustomerShippingAddressCd;
            //
            // Note: Update companyCustomerCd into SJIAppContext immediately. Which need to inject to request header and send back to server side.
            //
            this.updateAppContext(this.appContext);
            this.http.post<SJIAppContext>(
                SJI_API_URLS.common_context_initappcontext + '?domain=' + this.appContext.domain,
                {}).subscribe((response) => {
                    console.log('Called initContext response: ' + JSON.stringify(response));
                    if (response) {
                        this.appContext.companyId = response.companyId;
                        this.appContext.companyTheme = response.companyTheme;
                        this.appContext.isAdminPortal = response.isAdminPortal;
                        this.appContext.modules = response.modules;
                        this.appContext.isAccessDocInvoice = response.isAccessDocInvoice;
                        this.appContext.isAccessDocMonthlyStatement = response.isAccessDocMonthlyStatement;
                        this.appContext.isIncomingShipmentReport = response.isIncomingShipmentReport;
                        this.appContext.isAccessDocSalesOrder = response.isAccessDocSalesOrder;
                        this.appContext.isAccessDocSalesOrderWh = response.isAccessDocSalesOrder;
                        this.appContext.isAccessDocShipment = response.isAccessDocShipment;
                        this.appContext.defaultOrderType = response.defaultOrderType;
                        this.appContext.companyCustomerTheme = response.companyCustomerTheme;
                        this.appContext.isTempPasswordLogin = response.isTempPasswordLogin;
                        this.appContext.language = response.language;
                        this.appContext.isDisableOrdering = response.isDisableOrdering;
                        this.appContext.isListPriceOnly = response.isListPriceOnly;
                        this.appContext.isHideDcPrice = response.isHideDcPrice;
                        this.appContext.currencyCd = response.currencyCd;
                        this.appContext.displayPhone = response.displayPhone;
                        this.appContext.fetLiable = response.fetLiable;
                        this.appContext.navCountyCode = response.navCountyCode;
                        this.appContext.hasTsRate = response.hasTsRate;
                        this.appContext.enableRefurbSearch = response.enableRefurbSearch;
                        this.appContext.freightSurchargeEnabled = response.freightSurchargeEnabled;
                        this.appContext.vendorCd = response.vendorCd;

                        if (response.companyCustomerCd) {
                            this.appContext.companyCustomerCd = response.companyCustomerCd;
                            this.appContext.navCustomerCd = response.navCustomerCd;
                        }
                        if (response.companyCustomerShippingAddressCd) {
                            this.appContext.companyCustomerShippingAddressCd = response.companyCustomerShippingAddressCd;
                            this.appContext.navShipToCode = response.navShipToCode;
                        }
                        if(this.appContext.sjiVersion !== response.sjiVersion){
                            this.isInitVersionChange = true;
                        }
                        //
                        // Update SJIAppContext again after got the refreshed whole SJIAppContext object from server side.
                        //
                        this.updateAppContext(this.appContext);
                        // Emit customer changed event to outside components.
                        this.customerChanged.emit(companyCustomerCd);
                    } else {
                        this.removeAppContext();
                        // Emit customer changed event to outside components.
                        this.customerChanged.emit(companyCustomerCd);
                    }
                });
        }
    }

    /**
     * Keep current selected company customer shipping address into appContext.
     * @param companyCustomerShippingAddressCd: current selected company customer shipping address from drop down.
     */
    updateSelectedCompanyCustomerShippingAddress(companyCustomerShippingAddressCd: string) {
        this.appContext = this.getAppContext();
        if (this.appContext && this.appContext.companyCustomerShippingAddressCd !== companyCustomerShippingAddressCd) {
            //
            // Note: Update companyCustomerShippingAddressCd into SJIAppContext immediately. Which need to inject to request header and send back to server side.
            //
            this.appContext.companyCustomerShippingAddressCd = companyCustomerShippingAddressCd;
            this.http.get<string>(SJI_API_URLS.common_context_getnavshiptocode,
                { params: { companyCustomerShippingAddressCd: companyCustomerShippingAddressCd } })
                .subscribe((response: string) => {
                    if (response && response.length > 0) {
                        this.appContext.navShipToCode = response;
                        console.log('updateSelectedCompanyCustomerShippingAddress called. SJIAppContext: ' + JSON.stringify(this.appContext));
                        this.updateAppContext(this.appContext);
                        // Emit customer ship to address changed event to outside components.
                        this.customerShipToChanged.emit(companyCustomerShippingAddressCd);
                    }
                });
        }
    }

 /**
     * emit select lang event.
     * @param companyCustomerShippingAddressCd: current selected company customer shipping address from drop down.
     */
    updateSelectedlanguage(lang: string) {
        this.appContext = this.getAppContext();
        if (this.appContext && this.appContext.language !== lang) {
            //
            // Note: Update language into SJIAppContext immediately.
            //
            this.appContext.language = lang;
            console.log('updateSelectedlanguage called. SJIAppContext: ' + JSON.stringify(this.appContext));
            this.updateAppContext(this.appContext);
            // Emit language changed event to outside components.
            this.languageChanged.emit(lang);
        }
    }

    /** Call serverside to refresh local appContext data. Called when update user profile. */
    refreshAppContext() {
        this.http.post<SJIAppContext>(
            SJI_API_URLS.common_context_initappcontext + '?domain=' + this.appContext.domain,
            {}).subscribe((response) => {
                console.log('Called initContext response: ' + JSON.stringify(response));
                if (response) {
                    this.appContext.companyId = response.companyId;
                    this.appContext.companyTheme = response.companyTheme;
                    this.appContext.isAdminPortal = response.isAdminPortal;
                    this.appContext.modules = response.modules;
                    this.appContext.isAccessDocInvoice = response.isAccessDocInvoice;
                    this.appContext.isAccessDocMonthlyStatement = response.isAccessDocMonthlyStatement;
                    this.appContext.isIncomingShipmentReport = response.isIncomingShipmentReport;
                    this.appContext.isAccessDocSalesOrder = response.isAccessDocSalesOrder;
                    this.appContext.isAccessDocSalesOrderWh = response.isAccessDocSalesOrder;
                    this.appContext.isAccessDocShipment = response.isAccessDocShipment;
                    this.appContext.defaultOrderType = response.defaultOrderType;
                    this.appContext.companyCustomerTheme = response.companyCustomerTheme;
                    this.appContext.isTempPasswordLogin = response.isTempPasswordLogin;
                    this.appContext.language = response.language;
                    this.appContext.isDisableOrdering = response.isDisableOrdering;
                    this.appContext.isListPriceOnly = response.isListPriceOnly;
                    this.appContext.isHideDcPrice = response.isHideDcPrice;
                    this.appContext.currencyCd = response.currencyCd;
                    this.appContext.displayPhone = response.displayPhone;
                    this.appContext.fetLiable = response.fetLiable;
                    this.appContext.navCountyCode = response.navCountyCode;
                    this.appContext.hasTsRate = response.hasTsRate;
                    this.appContext.enableRefurbSearch = response.enableRefurbSearch;
                    this.appContext.freightSurchargeEnabled = response.freightSurchargeEnabled;

                    if (response.companyCustomerCd) {
                        this.appContext.companyCustomerCd = response.companyCustomerCd;
                        this.appContext.navCustomerCd = response.navCustomerCd;
                    }
                    if (response.companyCustomerShippingAddressCd) {
                        this.appContext.companyCustomerShippingAddressCd = response.companyCustomerShippingAddressCd;
                        this.appContext.navShipToCode = response.navShipToCode;
                    }
                    if(this.appContext.sjiVersion !== response.sjiVersion){
                        this.isInitVersionChange = true;
                    }
                    //
                    // Update SJIAppContext again after got the refreshed whole SJIAppContext object from server side.
                    //
                    this.updateAppContext(this.appContext);
                    // Emit customer changed event to outside components.
                    // this.customerChanged.emit(this.appContext.companyCustomerCd);
                } else {
                    this.removeAppContext();
                    // Emit customer changed event to outside components.
                    // this.customerChanged.emit(this.appContext.companyCustomerCd);
                }
            });
      }
}
