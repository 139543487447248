export class WarehouseDetailsResult {
    orderNum?: string;
    orderDate?: Date;
    orderShipTo?: string;
    orderTotalAmount?: number;
    eta_Arrival?: Date;
    isDirectContainer?: boolean;
    items: Item[];
}
export class Item {
    invoice?: string;
    status?: string;
}