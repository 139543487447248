import { Languages } from './../../../_enumerations/languages';

/**
 * Keep current selected CompanyCustomer
 * and its associated company customer shipping address.
 * Reference tables: CompayCustomer, CompanyCustomerShippingAddress, CompanyCustomerUser.
 */
export class SJIAppContext {

    /**
     *  By default value = false. if field "IsAdminPortal" = true in table [dbo].[ClientDomain],
     *  set this value = true, which means current accessing portal is admin.
     *  Note: If isAdminPortal = true, there will be no companyId. Since Admin portal is not company portal.
     * */
    isAdminPortal: boolean;

    /** Associated company's CompanyId. When access one company domain. set this Id with CliamDomain table.
     *  Reference to field "Id" in table [dbo].[Company].
     *  It is integer value.
    */
    companyId: string;

    /** When got current access company, get company's theme from Company.ThemeId -> ThemeCode */
    companyTheme: string;

    /** companyName*/
    companyName: string;

    /** Reference to CompanyCustomerCd. Primary key of table [CompanyCustomer]. When select customer from drop down, update this value.
     *  Data format: "NavCustomerCd" + "_" + "CompanyId".
     */
    companyCustomerCd: string;

    /**
     *   Field "NavCustomerCd" in table dbo.CompanyCustomer.
     *   Work for Navision push.
     *   Corporate with CompanyCustomerCd.
     */
    navCustomerCd: string;

    /** Selected Shipping Address associated to current selected CompanyCustomer.
     * When select customer address from drop down, update this value.
     * Reference to field "CompanyCustomerShippingAddressCd" in table [dbo].[CompanyCustomerShippingAddress].
     * Data format: "NavCustomerCd" + "_" + "NavShipToCode" + "_" + "CompanyId".
     * */
    companyCustomerShippingAddressCd: string;

    /**
     * Field "NavShipToCode" in table dbo.CompanyCustomerShippingAddress.
     * Work for Navision push.
     * Corporate with CompanyCustomerShippingAddressCd.
     */
    navShipToCode: string;

    /**
     * Field "Theme" in table CompanyCustomer.
     * Reference to old SecurityFramework.CustomerCompany table field "ThemeId"->Theme.Code
    */
    companyCustomerTheme: string;

    /**
     * Current access company portal's domain. Same as field "Domain" in table ClientDomain.
     */
    domain: string;

    /**
     * Current applied language. Note: Different with logon user's default language.
     * Default value same as logon user's langauge. But user is able to reset this language in the Home page language drop down.
     */
    language: string;

    /** list of module ids which associated current accessing company (dbo.Company.Id)
     *  and selected customer (dbo.CompanyCustomer.CompanyCustomerCd).
     * */
    modules: [number];

    /** Flag to allow Document Center for Sales Order. (Direct)*/
    isAccessDocSalesOrder: boolean;

    /** Flag to allow Document Center for Sales Order. (Warehouse)*/
    isAccessDocSalesOrderWh: boolean;

    /** Flag to allow Document Center for Shipment. */
    isAccessDocShipment: boolean;

    /** Flag to allow Document Center for Invoice. */
    isAccessDocInvoice: boolean;

    /** Flag to allow Document Center for Monthly Statement */
    isAccessDocMonthlyStatement: boolean;

    /** Flag to allow Document Center for Incoming Shipment Report */
    isIncomingShipmentReport: boolean;

    /** value = "WH" (Warehouse Order) or "DC" (Direct Container Order) */
    defaultOrderType: string;

    /**
     * Reference to field "IsTempPassword" in table [dbo].[User].
     * When it is user's first time login with temp password (after forget password processed).
     * System need to send client to password setup page to setup its personal password.
     * Default value = false.
     */
    isTempPasswordLogin: boolean;
    /**
     *  When the disableOrdering flag in user table is true, we will have to restrict user from adding items to cart and checking out.
     *  We should not show the cart page, top cart information bar and the add to cart buttons.
     *  This user will still have access to the ordering module, but these type of users will only view products and prices – but not to actually place order.
     */
    isDisableOrdering: boolean;

    /**
     * Don't show this checkbox(isListPriceOnly) if isListPriceOnly ==true
     * Reference to field "IsListPriceOnly" in table dbo.CompanyUser.
     */
    isListPriceOnly: boolean;

    /**
     * Bound to isHideDcPrice in COmpanyUser table - used to hide Factory Direct Price display on warehouse page - no affect on checkout
     */
    isHideDcPrice: boolean;

    /**
     * Reference to field "NavCurrencyCd" in table dbo.Company.
     *
     */
    currencyCd: string;

    /** Reference to field "DisplayPhone" in table dbo.CompanyDetail */
    displayPhone: string;
    /** Reference to field "DisplayEmail" in table dbo.CompanyDetail */
    displayEmail: string;
    /** Reference to field "DisplayFax" in table dbo.CompanyDetail */
    displayFax: string;
    /** Reference to field "fetLiable" in table dbo.CompanyCustomerShippingAddresss */
    fetLiable: string;

    /** Reference to field "fetLiable" in table dbo.CompanyCustomerShippingAddresss */
    navCountyCode: string;

    /**
     * Web API deployment version. Work for auto refresh angular project in browser after deploy new version.
     * Note: Each time deploy new version, update this value.
     * Corporate with web api method "InitAppContext".
     */
    sjiVersion: string;
    /**
     *  Default value = false.
     *  Check TsRate from table  Order.TsrateDetails and table dbo.CompanyCustomerShippingAddress
     *  with current company Id and current selected CompanyCustomerShippingAddressCd
     *  Check if it has TsRate.
     */
    hasTsRate: boolean;
    itemsPerPage: number;
    isWebServicePrice: boolean;
    mobileWebsite:string;
    /** Refer to column "EnableRefurbSearch" in table dbo.CompanySetting */
    enableRefurbSearch: boolean;

    /**
     * Corporate with sever side web api appsetting called "CompaniesFreightSurchargeEnabled".
     * Default value = false.
     * If current processing company Id is defined in the CompaniesFreightSurchargeEnabled collection, set this property as "true", otherwise value = false.
     *  Work for enable/disable Freight Surcharge item calcuation feature in shopping cart process methods.
     *  Now, Maxam Tire North America company portal need to hide "Freight Surcharge" item in shopping cart.
     *  Work for task 16. https://chinahelloworld.visualstudio.com/Dynamic-Tire-Solution/_workitems/edit/16
     */
    freightSurchargeEnabled: boolean;
    //Vendor portal
    vendorCd: string;

    constructor() {
      this.isAdminPortal = false;
      this.isTempPasswordLogin = false;
      this.isAccessDocInvoice = false;
      this.isAccessDocShipment = false;
      this.isAccessDocMonthlyStatement = false;
      this.isIncomingShipmentReport = false;
      this.isAccessDocSalesOrder = false;
      this.isAccessDocSalesOrderWh = false;
      this.hasTsRate = false;
      this.enableRefurbSearch = false;
      this.itemsPerPage = 20;
      this.freightSurchargeEnabled = false;
    }
}
