import { SecurityOidcService } from './../services/system/security.oidc.service';
import { ErrorResponse } from '../models/system/errorresponse';
import { MessageService } from '../services/system/message.service';
import { element } from 'protractor';
import { Message } from '../models/system/message';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { _throw } from 'rxjs/observable/throw';
import 'rxjs/add/operator/catch';
import { MessageTypes } from 'src/app/_enumerations/messagetypes';

/**
 * Intercepts the HTTP responses, and in case that an error/exception is thrown, handles it
 * and extract the relevant information of it.
 * Reference: https://stackoverflow.com/questions/46019771/catching-errors-in-angular-httpclient
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private messageService: MessageService, public securityService: SecurityOidcService) {}
    /**
     * Intercepts an outgoing HTTP request, executes it and handles any error that could be triggered in execution.
     * @see HttpInterceptor
     * @param req the outgoing HTTP request
     * @param next a HTTP request handler
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .catch(errorResponse => {
                if (errorResponse instanceof HttpErrorResponse) {
                    //HTTP Status Codes: https://www.restapitutorial.com/httpstatuscodes.html
                    if (errorResponse.status === 400) {
                         //
                         // try to collect server side business model's validation error here.
                         // Corporate with .net returned BadRequest(ModelState).
                         //
                         const validationErrorDictionary = errorResponse.error;
                         if (validationErrorDictionary) {
                            for (var fieldName in validationErrorDictionary) {
                                if (validationErrorDictionary.hasOwnProperty(fieldName)) {
                                    const msg = validationErrorDictionary[fieldName]; // this could be arry such as [''] for business model validations.
                                    this.messageService.error(msg.join('<br/>'));
                                }
                            }
                        }
                    } else if (errorResponse.status === 401) {
                        // tslint:disable-next-line:max-line-length
                        this.messageService.error('Unauthorized access.'); // Error Code. Work for ngx-translate to translate with multiple languages.
                        // this.securityService.login();
                    } else if (errorResponse.status === 500) {
                            //
                            // Corporate with server side middleware called "RequestVersionMiddleware".
                            // If client side version is not the same as server side.
                            // Notify user to do refresh.
                            //
                            console.log('errorReponse: ' + JSON.stringify(errorResponse));

                            if (errorResponse.error.message === 'invalid version') {
                               this.messageService.addDialog('Core.RefeshToGetLatestVersion', MessageTypes.Refresh);
                            } else {
                                this.messageService.error('Core.InternalServerError'); // Error Code. Work for multiple languages support.
                            }
                    } else if (errorResponse.status === 0) {
                        this.messageService.error('Our online ordering system is currently busy. Please refresh the page or try again in a couple of minutes.'); // Error Code. Work for multiple languages support.
                       // this.securityService.login();
                    }
                       // Publish the server side validation messages to UI.
                       this.messageService.emitMessages();
                }

                return _throw(errorResponse); //Observable.throw(errorResponse); // _throw(errMsg);
            });
    }
}

/**
 * Provider POJO for the interceptor
 */
export const HttpErrorInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
};

