

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './notfound.component.html',
})
export class NotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}
