import { Theme } from '../../models/system/theme';

/**
 * Default Theme if application no specified theme definition.
 * Note: Default theme is same settings as Theme Dynamic_Tire.
 * */
export const Theme_Default: Theme = {
  name: 'Default',
  properties: {
     /* own color */
     '--mainColor': '#FFF', // Color 2
     '--mainButtomColor': '#FFF', // main Buttom
     '--minorColor': '#999', // Color 3
     '--mainColorB':'#FFF',
     '--subColor': '#444', // Color 1.
     '--mainBackgroundColor': '#BBB', // Background
     '--mainTitleColor':'#f2f4f9',
     '--minorBackgroundColor': '#CCC', // NAV
     '--importantLabel': '#333',
     '--ordinaryLabel': '#666',
     '--nonImportantLabel': '#999',
     '--dashColor1': '#abb5c4',
     '--dashColor2': '#BBB',
     '--controlColor_span1': '#C31C28',
     '--controlInptBackgroundColor1': '#f3f2f2',
     '--navTitleColor': '#FFFFFF',
     '--navTitleMinorColor': '#FFFFFF',

     '--extraPriceColor':'#287f3d',    
     '--inTransitColor':'#0435c5',
     '--cartIconColor':'#FFFFFF',  
     '--grid_th_Color': '#F6F6F6',
     '--grid_td_Color': '#FFFFFF',

     /*hearder*/
     '--navbarBackground': '#FFFFFF',
     '--headerClickColor': '#C31C28',
     '--headerClickColor_border': '#090e2126',
     /*order*/
     '--grid_thColor': '#F6F6F6',
     '--controlColor_order_buttom1': '#C31C28',
     '--controlColor_order_buttom2': '#0A0E22',
     '--controlColor_order_buttom3': '#090E22',
     '--controlColor_order_buttom4': '#090e21',
     '--controlColor_buttomBorder1': '#c31c28',
     /*cart*/
     '--controlColor_buttom2': '#090E22',
     '--controlColor_buttom3': '#C31C28',
     '--controlColor_buttom2_font': '#FFFFFF',
     '--dialogBackgroundColor': '#FFFFFF',

     '--noFillColor':'#62697b',
     '--disableCarat':"#ccc",

     /*Direct Container Order Grid */
     '--GridRightBorderTH': '#474A58',
     '--GridRightBorderTD': '#CCC',
     '--GridHoverColorTR':'#F09BA2',
     '--TextOnHover': '#FFFFFF'

     /* Bootstrap css override */
  }
};
