import { APP_CONFIGS } from './../../../../_configs/appconfigs';
import { SecurityOidcService } from './../../../services/system/security.oidc.service';
import { MessageTypes } from '../../../../_enumerations/messagetypes';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from '../../../services/system/message.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/Subscription';

/**
 * Show popup dialog box for showing additinal application messages for user in UI.
 * Note: This popup dialog box is seperated from message box. They are able to show at same time.
 * Note: Now, we only support dialog box for conversation interaction.
 * Reference: http://jasonwatmore.com/post/2016/12/01/angular-2-communicating-between-components-with-observable-subject
 */
@Component({
   selector: 'app-dialog',
   templateUrl: './dialog.component.html'
})
export class DialogComponent implements OnInit, OnDestroy {
   title: string;
   content: string;
   dialogOpened = false;
   subscription: Subscription;
   messageType: MessageTypes;
   expirationSeconds = APP_CONFIGS.accessTokenExpiringNotificationTime;
   originalContent = '';
   expirationTimer: any;
   constructor(private messageService: MessageService, private translateService: TranslateService,
    private securityService: SecurityOidcService) { }

   ngOnInit() {
      this.subscription = this.messageService.getDialog().subscribe(conversation => {
         if (conversation) {
           this.messageType = conversation.type;
           if (conversation.type) {
                 switch (conversation.type) {
                   case 'Info': this.title = this.translateService.instant('Core.Information'); break ;
                   case 'Success': this.title = this.translateService.instant('Core.Success'); break ;
                   case 'Warning': this.title = this.translateService.instant('Core.Warning'); break ;
                   case 'Error': this.title = this.translateService.instant('Core.Error'); break ;
                   case 'Expiration': this.title = this.translateService.instant('Core.Warning'); break;
                   default: this.title = this.translateService.instant('Core.Information') ;
                 }
           }
           //
           // Try to translate message with multiple languages (if message has correct key in languages json files).
           //
           this.content = this.translateService.instant(conversation.message);
           this.dialogOpened = true;
           if (conversation.type === 'Expiration') {
              this.expirationCount();
            }
          }
        });
   }

   ngOnDestroy() {
     // unsubscribe to ensure no memory leaks
     this.subscription.unsubscribe();
     if (this.expirationTimer !== undefined && this.expirationTimer != null) {
       clearInterval(this.expirationTimer);
     }
   }

   /** Close the dialog */
   public close(status) {
    this.dialogOpened = false;
  }

  /**
   *  Continue user's login session. Trigger refresh token process.
   * */
  public continueLogin() {
      // Called ssigninSilent method.
      if (this.expirationSeconds > 0) {
        this.securityService.renewToken();
        this.dialogOpened = false;
      } else {
        this.securityService.login();
      }
   }

  private expirationCount(): void {
    this.originalContent = this.content;
    this.expirationTimer = setInterval(() => {
        this.expirationSeconds--;
        this.content = this.originalContent.replace('#', this.expirationSeconds.toString());
        if (this.expirationSeconds <= 0) {
            this.content = this.translateService.instant('Core.SessionTimeout');
            return;
        }
    }, 1000);
  }

  /** Refesh local browser to get latest version package. Work for deployment.
   *  Signout user.
   */
  public RefreshVersion() {
    this.dialogOpened = false;
    this.securityService.signout();
  }
}
