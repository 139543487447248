import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';

import { PromotionComponent } from './promotion/promotion.component';
import { ComboBoxDropDownComponent } from './comboBox/comboBoxDropDown';
import { PagingComponent } from './paging/paging.component';
import { TrackingOrdersComponent } from '../order/components/trackingOrders.component';
import { TrackingPhoneOrdersComponent } from '../order/components/trackingPhoneOrders.component';
import { TrackingOtherOrdersComponent } from '../order/components/trackingOtherOrders.component';
@NgModule({
    imports: [
      SharedModule
    ],
    declarations: [
      PromotionComponent,
      ComboBoxDropDownComponent,
      PagingComponent,
      TrackingOrdersComponent,
      TrackingPhoneOrdersComponent,
      TrackingOtherOrdersComponent
    ],
    exports: [
      PromotionComponent,
      ComboBoxDropDownComponent,
      PagingComponent,
      TrackingOrdersComponent,
      TrackingPhoneOrdersComponent,
      TrackingOtherOrdersComponent
    ]
  })
  export class SJISharedModule { }
