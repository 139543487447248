import { Component, OnInit } from '@angular/core';
import { SJIService } from '../../services/sji.service';
import { SJI_API_URLS } from '../../_configs/webapiurls';
import { TrackingOrdersSearch } from '../models/trackingOrdersSearch';
import { WarehouseDetailsResult } from '../models/warehouseDetailsResult';
import { DirectContainerResult } from '../models/directContainerResult';
import * as m from '../models/trackingOrders';
import { MessageService } from 'src/app/core/services/system/message.service';
import { SJIAppContextService } from '../../../core/services/system/sji.appcontext.service';
@Component({
  selector: 'app-other-orders',
  templateUrl: './trackingOtherOrders.component.html',
  styleUrls: ['./trackingOrders.component.scss']
})
export class TrackingOtherOrdersComponent implements OnInit {

  trackingOrdersSearch: TrackingOrdersSearch = { showType: '1', isIndex: true };
  orderList: m.TrackingOrders[] = [{}];
  showWarehouseRow: WarehouseDetailsResult = new WarehouseDetailsResult();
  showContainerRow: DirectContainerResult = new DirectContainerResult();

  isTrackOrderDialog: boolean;
  WarehouseDialog: boolean;
  ContainerDialog: boolean;
  isaddCartFailure: boolean;
  isaddCartSuccessfully: boolean;
  isIndex: boolean;

  isNewTrackOrderModule = false;

  MaxamContainerDialog = {
    isShow: false,
    title: '',
    model: {},
    isDetail: false,
    detail: {currentStatus: 1},
    steps: [],
    height: 500
  }

  constructor(
    private sjiService: SJIService,
    private messageService: MessageService,
    private appContextService: SJIAppContextService
    ) {
    const toDate = new Date();
    const formDate = new Date(toDate.setDate(toDate.getDate() - 180));
    this.trackingOrdersSearch.from = formDate;
    this.trackingOrdersSearch.to = new Date();
    this.isaddCartFailure = false;
    this.isaddCartSuccessfully = false;
    this.isIndex = false;
  }

  ngOnInit() {
    let data = this.appContextService.getAppContext();

    this.isNewTrackOrderModule = data.modules.indexOf(16) > -1;
    this.search();
    // this.orderList = [{
    //   orderNum: 'AW1234', customerPONum: 'PO TEST', orderPlacedDate: '2018-06-05',
    //   orderShip_to: '123sushi', isDirectContainer: false
    // }];
  }
  public search() {
    const result: TrackingOrdersSearch = this.sjiService.cloneObject(this.trackingOrdersSearch);
    result.from = this.sjiService.theCorrectTimeZone(this.trackingOrdersSearch.from);
    result.to = this.sjiService.theCorrectTimeZone(this.trackingOrdersSearch.to);
    this.sjiService.post(SJI_API_URLS.order_tracking_other_order_search, result).subscribe((data) => {
      this.orderList = data as m.TrackingOrders[];
      console.log('order search ----------------------------------');
      console.log(data);
    });
  }
  public trackingOrder(orderNum, isDirectContainer) {
    // this.isTrackOrderDialog = true;

    if (isDirectContainer) {
      console.log('Show DC : ' + orderNum);
      // this.isDCOrder = true;
      if(this.isNewTrackOrderModule){
        this.showMaxamDirectContainerDetails(orderNum);
      }else{
        this.showDirectContainerDetails(orderNum);
      }

    } else {
      console.log('Show EC : ' + orderNum);
      // this.isDCOrder = false;
      this.showWarehouseDetails(orderNum);
    }
  }

  public showWarehouseDetails(orderId: string) {
    const url = `${SJI_API_URLS.order_tracking_showwarehousedetails}?orderId=${orderId}`;
    console.log('showWarehouseDetails: ' + url);
    this.sjiService.get(url).subscribe((data) => {
      console.log('showWarehouseRow', data);
      this.showWarehouseRow = data as WarehouseDetailsResult;
       this.WarehouseDialog = true;
    });
  }

  public showDirectContainerDetails(orderId: string) {
    const url = `${SJI_API_URLS.order_tracking_showdirectcontainerdetails}?orderId=${orderId}`;
    console.log('showDirectContainerDetails: ' + url);
    this.sjiService.get(url).subscribe((data) => {
      console.log('showWarehouseRow', data);
      this.showContainerRow = data as DirectContainerResult;
      this.ContainerDialog = true;
    });
  }
  public showMaxamDirectContainerDetails(orderId: string) {
    let url = `${SJI_API_URLS.order_tracking_detail}?id=${orderId}`;
    this.sjiService.get(url).subscribe((result: any) => {
      if (result.isSuccess) {
        this.MaxamContainerDialog.model = result.data;
        this.MaxamContainerDialog.title = "Tracking.OrderTracking";
        this.MaxamContainerDialog.height = 500;
        this.MaxamContainerDialog.isShow = true;
        this.MaxamContainerDialog.isDetail = false;
      } else {
        this.messageService.error(result.message);
        this.messageService.emitMessages();
      }
    });
  }
  public showContainerDetail(item: any) {
    this.MaxamContainerDialog.detail = item;
    this.MaxamContainerDialog.title = "Tracking.ContainerList";
    this.MaxamContainerDialog.height = item.containerNum > 2 ? 850 : 650;
    this.MaxamContainerDialog.isDetail = true;
    this.MaxamContainerDialog.steps = [
      { type: 1, title: "Tracking.Factory", description: item.factoryDesc, etd: item.factoryETD, atd: item.factoryATD},
      { type: 0, title: "Tracking.ToBeShipped"},
      { type: 1, title: "Tracking.PortOfLoading", description: item.polDesc, etd: item.polETD, atd: item.polATD},
      { type: 0, title: "Tracking.InTransit"},
      { type: 1, title: "Tracking.PortOfDischarge", description: item.podDesc, etd: item.podETAUpdate, atd: item.podATA},
      { type: 0, title: "Tracking.InTransit"},
      { type: 1, title: "Tracking.CarrierFnd", description: item.carrierFndDesc, etd: item.carrierFndETA, atd: item.carrierFndATA},
      { type: 0, title: "Tracking.Delivering"},
      { type: 1, title: "Tracking.FinalDestination", description: item.cargoFndDesc, etd: item.cargoFndETA, atd: item.cargoFndATA},
      { type: 0, title: "Tracking.EmptyReturning"},
      { type: 1, title: "Tracking.ContainerYard", description: item.carrierYardDesc, etd: item.carrierYardETA, atd: item.carrierYardATA}
    ];
  }
  public containerClose() {
    if(this.MaxamContainerDialog.isDetail){
      this.MaxamContainerDialog.title = "Tracking.OrderTracking";
      this.MaxamContainerDialog.height = 500;
      this.MaxamContainerDialog.isDetail = false;
    }else{
      this.MaxamContainerDialog.isShow = false;
    }
  }
}
