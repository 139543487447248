import { WheelPosition } from './../../claims/models/national.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResetPasswordModel } from '../models/ResetPasswordModel';
import { SJI_API_URLS } from '../../_configs/webapiurls';

@Injectable()
export class UserProfileService {
  constructor(private http: HttpClient) {}

  resetPassword(model: ResetPasswordModel) {
     return this.http.post<ResetPasswordModel>(SJI_API_URLS.myProfile_resetPassword, model);
  }
}
