export class DirectContainerResult {
    orderNum?: string;
    orderDate?: Date;
    isDirectContainer?: boolean;
    items: Item[];
}
export class Item {
    performa?: number;
    invoiceNum: string;
    containerNum: string;
    status?: number;
    eta_Arrival?: Date;
}