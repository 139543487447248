import { ThemingService } from './../theming/theming.service';
import { Theme } from './../models/system/theme';
import { Directive, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appTheme]'
})
export class ThemeDirective implements OnInit, OnDestroy {

  private destroy$ = new Subject();

  constructor(
    private elementRef: ElementRef,
    private themeService: ThemingService
  ) { }

  ngOnInit() {
    const active = this.themeService.getActiveTheme();
    console.log('theme directive called. current theme' + active.name);
    if (active) {
      this.updateTheme(active);
    }

    this.themeService.themeChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((theme: Theme) => this.updateTheme(theme));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateTheme(theme: Theme) {
    // project properties onto the element
    for (const key in theme.properties) {
      this.elementRef.nativeElement.style.setProperty(key, theme.properties[key]);
    }

    // remove old theme
    // for (const name of this.themeService.theme) {
    //   this.elementRef.nativeElement.classList.remove(`${name}`);
    // }

    // alias element with theme name
    // this.elementRef.nativeElement.classList.add(`${theme.name}`);
  }
}
