import { MultiTranslateHttpLoader } from '../../core/services/system/multi.translations.http.loader.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';

/**
 * Corporate with @ngx-translate multiple languages support library.
 * Load multiple languages files from "./assets/i18n/*.json".
 * Reference: https://github.com/ngx-translate/core
 * https://stackoverflow.com/questions/47312962/angular-5-internationalization
 * https://stackblitz.com/github/ngx-translate/example
 *
 * Note: For performance, each module only load json files under the folder with name of module name.
 */
export function SJITranslateLoader(http: HttpClient) {
     // return new TranslateHttpLoader(http, './assets/i18n/demo/', '.json');
    return new MultiTranslateHttpLoader(http, [
        {prefix: './assets/sji/i18n/core/', suffix: '.json'},
        {prefix: './assets/sji/i18n/cart/', suffix: '.json'},
        {prefix: './assets/sji/i18n/order/', suffix: '.json'},
        {prefix: './assets/sji/i18n/claims/', suffix: '.json'},
        {prefix: './assets/sji/i18n/user/', suffix: '.json'}
      ]);
}
