import { MessageService } from './message.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { SecurityOidcService } from './security.oidc.service';
import { Observable } from 'rxjs/Observable';
import { SJIAppContextService } from './sji.appcontext.service';

/**
* The auth guard is used to prevent unauthenticated users from accessing restricted routes.
* NOTE: While it would be possible to bypass this client side authentication check by manually
* adding a 'currentUser' object to local storage using browser dev tools,
* this would only give access to the client side routes/components,
* it wouldn't give access to any real secure data from the server api
* because a valid authentication token (JWT) is required for this.
*/
@Injectable()
export class SecurityOidcGuardService implements CanActivate {
  constructor(private authService: SecurityOidcService,
    private appContextService: SJIAppContextService,
    private router: Router, private messageService: MessageService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
     let result = false;
     let roleCheckingPassed = false;
     let isLogin = false;

     if (this.authService.isLoggedIn()) {
        isLogin = true;
        //
        // Check user roles and permissions here.
        //
        const expectedRole = route.data.role;
        // console.log('admin expected role:' + expectedRole);
        if (expectedRole && expectedRole != null) {
            roleCheckingPassed = (this.authService.checkUserRole(expectedRole));
        } else {
            // If no customized user role setting, system will assume it is accessable if user has authenticated.
            roleCheckingPassed = true;
        }
      } else {
        isLogin = false;
        // if usr didn't login or login expired, redirect to login page.
        localStorage.setItem('returnUrl', state.url);
        this.authService.login();
      }

      //
      // if user can login, but his/her role do not allow to access specified url, showing warning messages.
      //
      if (isLogin && !roleCheckingPassed) {
           this.messageService.warning('Unauthorized access');
           this.messageService.emitMessages();
      }

      if (isLogin === false) {
        result = false;
      } else {
        result = roleCheckingPassed;
      }

      if (isLogin && this.appContextService.getAppContext().isTempPasswordLogin) {
          //
          // If user logined, but he/she is login with temporary password,
          // system will push them to resetpassword page to reset new password first.
          //
          result = false;
          this.router.navigate(['userprofile/resetpassword']);
      }

      return result;
  }
}
