import { SJIAppContext } from './../models/system/sjiappcontext';
import { Injectable, Inject, EventEmitter } from '@angular/core';
import { THEMES, ACTIVE_THEME, Theme } from '../models/system/theme';
import { APP_CONSTS } from '../../_configs/consts';
/**
 * Reference: https://stackblitz.com/edit/angular-themeing?embed=1&file=src/app/app.component.ts
 */
@Injectable()
export class ThemingService {
  private currentDomain = '';
  themeChange = new EventEmitter<Theme>();

  constructor(
    @Inject(THEMES) public themes: Theme[],
    /**
     * Keep current active theme name such as "Theme1" (company Id = 1), "Theme2" (company Id = 2), ...
    **/
    @Inject(ACTIVE_THEME) public theme: string
  ) {
     this.currentDomain = this.getCurrentDomain();
  }

  /**
   *  Called in system initialize stage to render UI based on specified theme which associated
   *  to current accessing company domain (got comapnyId from appContext).
   *  Note: This method has to be called after SJIAppContextService.init() method.
   *  CompanyId needs to be got first based on accesssing company portal domain.
   *
   * */
  init() {
     const appContext: SJIAppContext = this.getAppContext();
     let activeTheme = 'Default'; // Matching to theme "ThemeDefault".
     if (appContext) {
        //
        // For company portal, theme based on company theme or current processing customer's theme.
        //
        if (!appContext.isAdminPortal) {
            console.log('theming service init: ' + JSON.stringify(appContext));
          if (appContext.companyCustomerTheme) {
            console.log('Company Customer Theme applied. '  + appContext.companyCustomerTheme);
            activeTheme = appContext.companyCustomerTheme;
          } else if (appContext.companyTheme) {
             console.log('Company Theme applied. ' + appContext.companyTheme);
             activeTheme = appContext.companyTheme; // Company theme following company Id value.
          } else {
            activeTheme = 'Default'; // By default, it is default theme.
          }
        } else {
          activeTheme = 'Default'; // Admin site applied 'Default' theme.
        }
     }
     console.log('Active Theme: ' + activeTheme);
     this.setTheme(activeTheme);
  }

  getTheme(name: string) {
    let theme = this.themes.find(t => t.name === name);
    if (!theme) {
      // throw new Error(`Theme not found: '${name}'`);
      console.log('Theme not found: ' + name);
      // Reset as default theme.
      theme = this.themes.find(t => t.name === 'Default');
    }
    return theme;
  }

  getActiveTheme() {
    return this.getTheme(this.theme);
  }

  getProperty(propName: string) {
    return this.getActiveTheme().properties[propName];
  }

  /**
   * Emit theme changed event and rendering UI with specified theme.
   * @param name: It is theme's code. Field Code in table dbo.Theme.
   */
  setTheme(name: string) {
    this.theme = name;
    this.themeChange.emit( this.getActiveTheme());
  }

  registerTheme(theme: Theme) {
    this.themes.push(theme);
  }

  updateTheme(name: string, properties: { [key: string]: string; }) {
    const theme = this.getTheme(name);
    theme.properties = {
      ...theme.properties,
      ...properties
    };

    if (name === this.theme) {
      this.themeChange.emit(theme);
    }
  }

   /**
     * Get current accessing company portal's information from local storage.
     */
    getAppContext(): SJIAppContext {
      if (localStorage.getItem(APP_CONSTS.appContext + this.currentDomain)) {
          return JSON.parse(localStorage.getItem(APP_CONSTS.appContext + this.currentDomain)) as SJIAppContext;
      } else {
          return null;
      }
  }

  getCurrentDomain() {
    let result = '';
    if (document.location.port.length === 0) {
        // Return domain without port. Note: Port = 80 as default.
        result = document.location.protocol + '//' + document.location.hostname ;
    } else {
        // Return domain with port. Note: Port != 80 in this case.
        result = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
    }
    return result;
}


}
