import { MessageTypes } from './../../../_enumerations/messagetypes';
import { MessageService } from './../../../core/services/system/message.service';
import { SecurityOidcService } from '../../../core/services/system/security.oidc.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SJIService } from '../../services/sji.service';
import { SJI_API_URLS } from '../../_configs/webapiurls';
import { SJIAppContextService } from '../../../core/services/system/sji.appcontext.service';
import { SJIAppContext } from '../../../core/models/system/sjiappcontext';
import { NavigationStart, Router} from '@angular/router';
import { Languages } from '../../../_enumerations/languages';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy  {
  //public orderMenuControl: any = {};

  userName = '';
  isLogin = false;
  item_class: string = "1";
  maxShowNav: string = "maxShowNav";
  logoToLoad: string = "";
  customerList: any = [];
  customerSourceList: any = [];
  customershiptoList: any = [];
  customershiptoSourceList: any = [];
  customerId: string = "";
  customershiptoId: string = "";
  languageList: any = [];
  languageId: string = "";
  sjiAppContext: SJIAppContext = new SJIAppContext();
  isOrders: boolean = true;
  isClaims: boolean = true;
  isDocumentCenter: boolean = true;
  isCart: boolean = true;

  isTrackorders: boolean = false;
  isWarehouse: boolean = false;
  isFactoryDirect: boolean = false;
  isWarrantyClaim: boolean = false;
  isNationalClaim: boolean = false;
  isMaxamNationalClaim: boolean = false;
  isReportingPriceSheetJobs: boolean = false;
  isBrowseCatalogs: boolean = false;
  isCompanyDialog: boolean = false;
  confirmDialog: boolean = false;
  imgStr: string = '';
  retutrnPolicyPDFStr : string = '';
  isDynamicSite:boolean = false;
  /**
   * Define if current logon user has assigned "Purchase Order" module.
   * Work for Vendor portal only.
   * Note: "Purchase Order" module belongs to Vendor Portal.
   */
  isPurchase:boolean = false;
  /**
   * Define if current logon user has assigned "Vendor Incoming Shipment" module.
   * Work for Vendor portal only.
   */
  isVendorIncomingShipment:boolean = false;
  isVendorInvoiceAndCreditMemo:boolean = false;
  /**
   * If current logon user no any assigned company customers, set this flag = true.
   * by default it is false.
   * If value = true, hide the company customers and company customers shipping addresses drop down on the top menu.
   *
   */
  noCustomers: boolean = false;
  verdorId: string = '';
  verdorList: any = [];

  /**
   * In current logic, for Vendor user login, they should not see "Policies" menu.
   * So, if current logon user assigned "Purchase Order" module only, set IsPolicies = false.
   * other scenarios, by default isPolicies = true.
   */
  isPolicies : boolean = true;

  //
  // Work for switch COVID warning policy pdf with English or French.
  //
  isShowCovid19 = false;
  //
  // Work for switch COVID warning policy pdf with English or French.
  //
  isShowConvidEnglish = true; // Show English version Convid
  isShowConvidFrench = false; // Show french version Convid
  link_click?: number;
  //
  // Note: When opn page "claims/maxamNational", hide the customer and customer ship to dropdrown on top menu.
  //
  displayCustomerDropdown = true;
  displayCustomerShipToDropdown = true;
  routeSubscription!: Subscription;

  constructor(
    private translateService: TranslateService,
    private securityService: SecurityOidcService,
    public sJIService: SJIService,
    private sjiAppContextService: SJIAppContextService,
    private router: Router,
    private messageService: MessageService,
  ) {
    const info = this.securityService.getClaims();
    if (info) {
      this.userName = info.name; this.isLogin = true;
    } else {
      this.userName = ''; this.isLogin = false;
    }
  }


  ngOnInit(): void {

    this.inItData();

    this.sjiAppContextService.customerChanged
      .subscribe((companyCustomerCd: string) => this.customerChanged(companyCustomerCd));

    this.sjiAppContextService.customerShipToChanged.
      subscribe((companyCustomerShippingAddressCd: string) => this.customerShippingAddressChanged(companyCustomerShippingAddressCd));

    this.sJIService.cartChanged.subscribe(() => this.cartChanged());

    //
    // Hide top menu customer and customer shipto dropdown when open Maxam Tire NA national cliam form.
    // Only work for comanyId = 13.
    //
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {

        if (event.url.indexOf("maxamNational")>-1) {
          this.displayCustomerDropdown = false;
          this.displayCustomerShipToDropdown = false;
        } else {
          this.displayCustomerDropdown = true;
          this.displayCustomerShipToDropdown = true;
        }
      }
    });

    if (this.router.url.indexOf("maxamNational")>-1) {
       this.displayCustomerDropdown = false;
       this.displayCustomerShipToDropdown = false;
    } else {
       this.displayCustomerDropdown = true;
       this.displayCustomerShipToDropdown = true;
    }
  }

 ngOnDestroy(): void {
   this.routeSubscription.unsubscribe();
 }

  /**
   * Get Global Application Context information associated to current logon user.
   */
  private getAppContext() {
    this.sjiAppContext = this.sjiAppContextService.getAppContext();
    this.isClaims = true;
    this.isOrders = true;
    this.isCart = true;
    this.isDocumentCenter = true;

    if(this.sjiAppContextService.isInitVersionChange){
      this.confirmDialog = true;
    }

    if (this.sjiAppContext.modules.indexOf(5) < 0 && this.sjiAppContext.modules.indexOf(6) < 0) {
      this.isClaims = false;
    }
    if (this.sjiAppContext.modules.length == null || this.sjiAppContext.modules.length < 1 || (this.sjiAppContext.modules.indexOf(1) < 0 && this.sjiAppContext.modules.indexOf(2) < 0 &&
    (this.sjiAppContext.modules.indexOf(7) < 0 && this.sjiAppContext.companyId != '2') && this.sjiAppContext.modules.indexOf(13) < 0 && this.sjiAppContext.modules.indexOf(14) < 0)) {
      this.isOrders = false;
    }
    if (this.sjiAppContext.modules.indexOf(4) < 0 || !(this.sjiAppContext.isAccessDocSalesOrder || this.sjiAppContext.isAccessDocInvoice || this.sjiAppContext.isAccessDocMonthlyStatement
      || this.sjiAppContext.isAccessDocShipment || this.sjiAppContext.isIncomingShipmentReport || (this.sjiAppContext.modules.indexOf(7) < 0 && this.sjiAppContext.companyId == '2'))) {
      this.isDocumentCenter = false;
    }
    if (this.sjiAppContext.modules.length == null || this.sjiAppContext.modules.length < 1 || (this.sjiAppContext.modules.indexOf(1) < 0 && this.sjiAppContext.modules.indexOf(2) < 0)) {
      this.isCart = false;
    }

    if (this.sjiAppContext.modules.indexOf(3) > -1 || this.sjiAppContext.modules.indexOf(16) > -1) {
      this.isTrackorders = true;
    }
    if (this.sjiAppContext.modules.indexOf(2) > -1) {
      this.isWarehouse = true;
    }
    if (this.sjiAppContext.modules.indexOf(1) > -1) {
      this.isFactoryDirect = true;
    }
    if (this.sjiAppContext.modules.indexOf(6) > -1) {
      this.isWarrantyClaim = true;
    }
    if (this.sjiAppContext.modules.indexOf(5) > -1) {
      this.isNationalClaim = true;
    }
    if (this.sjiAppContext.modules.indexOf(7) > -1) {
      this.isBrowseCatalogs = true;
    }

    //
    // Define if current logon user has assigned "Purchase Order" module.
    //
    if (this.sjiAppContext.modules.indexOf(9) > -1) {
      this.isPurchase = true;
    }
    //
    // Define if current logon user has assigned "Vendor Incoming Shipment" module.
    //
    if (this.sjiAppContext.modules.indexOf(10) > -1) {
      this.isVendorIncomingShipment = true;
    }
    //
    // Define if current logon user has assigned "Invoice & Credit Memo" module.
    //
    if (this.sjiAppContext.modules.indexOf(11) > -1) {
      this.isVendorInvoiceAndCreditMemo = true;
    }
    //
    // Define if current logon user has assigned "National Maxam Claim" module.
    //
    if (this.sjiAppContext.modules.indexOf(12) > -1) {
      this.isMaxamNationalClaim = true;
    }
    if (this.sjiAppContext.modules.indexOf(13) > -1) {
      this.isReportingPriceSheetJobs = true;
    }
    //
    // If current logon user is vendor user (only assigned "Purchase Order,Vendor Incoming Shipment,Invoice & Credit Memo" Module),
    // the "Policies" menu need to be hidden.
    //
    if(this.sjiAppContext.modules.length <= 3){
      let changePolicies = true;
      for (const i of this.sjiAppContext.modules) {
        const vendorModules = [9, 10, 11];
        if(vendorModules.indexOf(i) < 0){
          changePolicies = false;
        }
      }
      if(changePolicies){
        this.isPolicies = false;
      }
    }

    if (this.sjiAppContext.companyCustomerTheme && this.sjiAppContext.companyCustomerTheme.length > 0) {
      this.logoToLoad = this.sjiAppContext.companyCustomerTheme;
    } else if (this.sjiAppContext.companyTheme && this.sjiAppContext.companyTheme.length > 0) {
      this.logoToLoad = this.sjiAppContext.companyTheme;
    }

    this.imgStr = `../../assets/images/logos/logo_${this.logoToLoad}.png`;

    this.retutrnPolicyPDFStr = `../../assets/files/Returns and Reaging Policy.pdf`;

    if( this.sjiAppContext.companyId == '2')
    {
      this.isDynamicSite = true;
      this.isShowCovid19 = true;
    } else {
      this.isDynamicSite = false;
      this.isShowCovid19 = false;
    }
  }
  /** Only called one time in constructor. Initialized the data.
   *  Note: We need to call initContext here one time.
   */
  private inItData() {

    this.lookup_languages();
    this.getAppContext();
    if (this.sjiAppContext.language === '1') {
      this.isShowConvidEnglish = true;
    } else {
      this.isShowConvidFrench = false;
    }

    this.getCustomer();

    //
    // Note: Only Vendor portal needs to get Vendors list.
    //
    if (this.isPurchase || this.isVendorIncomingShipment || this.isVendorInvoiceAndCreditMemo)
    {
      this.getVendor();
    }

    this.sJIService.common_gettotalcount();
  }

  customerChanged(companyCustomerCd: string) {
    //console.log('header listen customer changed. ' + companyCustomerCd);
    // Refresh shopping card summary.
    this.sJIService.common_gettotalcount();
    this.getAppContext();
  }

  customerShippingAddressChanged(companyCustomerShippingAddressCd: string) {
    console.log('header listen customer shipping address changed. ' + companyCustomerShippingAddressCd);
    this.sJIService.companyCustomerShippingAddressCd = companyCustomerShippingAddressCd;
    // Refresh shopping card summary.
    this.sJIService.common_gettotalcount();
    this.getAppContext();
  }

  /** Refresh Cart Info when cart item added/removed/updated. */
  cartChanged() {
    this.sJIService.common_gettotalcount();
  }

  // setMenuShow() {
  //   this.sJIService.get(SJI_API_URLS.Order_Getusersettings).subscribe(reslut => {
  //     this.orderMenuControl = reslut as any;
  //   });

  // }
  signout(e) {
    // Prevent from trigger form submit.
    e.preventDefault();
    this.securityService.signout();
  }

  signin(e) {
    e.preventDefault();
    this.securityService.login();
  }

  renewToken(e) {
    e.preventDefault();
    // this.securityService.renewToken();
    this.messageService.addDialog('Core.GoingToExpire', MessageTypes.Expiration);
  }

  public click_sign(index) {
    let self = this;
    self.item_class = index;
    self.maxShowNav = "maxShowNav";
    self.link_click = null;
  }

  public click_showMaxNan() {
    let self = this;
    if (self.maxShowNav == null) {
      self.maxShowNav = "maxShowNav";
    } else {
      self.maxShowNav = null;
    }
  }

  /**
   * When selected Customers from the drop down.
   * @param value selected CompanyCustomerCd.
   * @returns selected CompanyCustomerCd.
   */
  async select_customer(value): Promise<string> {
    //
    // If customer changed, customer's associated shipping address also need to change and update.
    // appContext selected customer and selected customer shipping address need to refresh.
    //
    const themeCode = await this.sjiAppContextService.setThemeBasedOnCompanyCustomer(value);

    //
    // Since customer changed, its associated shipping address also need to be updated.
    //
    let selectedCompanyCustomerShippingAddressCd = this.sjiAppContext.companyCustomerShippingAddressCd;

    //
    // Refresh shipping addres list based on selected customer.
    //
    await this.customershipto(this.customerId);

    console.log('selectedCompanyCustomerShippingAddressCd 1: ' + selectedCompanyCustomerShippingAddressCd);
    if (this.customershiptoList && this.customershiptoList.length > 0) {
      console.log('this.customershiptoList[0].key ' + this.customershiptoList[0].key);

      if (selectedCompanyCustomerShippingAddressCd !== this.customershiptoList[0].key) {
        selectedCompanyCustomerShippingAddressCd = this.customershiptoList[0].key;
      }
    } else {
      selectedCompanyCustomerShippingAddressCd = null;
    }

    console.log('selectedCompanyCustomerShippingAddressCd 2: ' + selectedCompanyCustomerShippingAddressCd);

    this.customershiptoId = selectedCompanyCustomerShippingAddressCd;
    this.sJIService.companyCustomerShippingAddressCd = selectedCompanyCustomerShippingAddressCd;
    // Note: Here, sjiAppContextService emit the customerChanged event.
    this.sjiAppContextService.updateSelectedCompanyCustomer(value, themeCode, selectedCompanyCustomerShippingAddressCd);

    // TODO: Can we use better way to refresh screen?
    // window.location.reload();

    return value;
  }

  public select_customershipto(value) {
    // Note: Here, sjiAppContextServie emit the customerShipToChanged event.
    this.sjiAppContextService.updateSelectedCompanyCustomerShippingAddress(value);
    // TODO: Can we use better way to refresh screen?
    // window.location.reload();
  }

  public select_language(lang: string) {
    // Note: Here, sjiAppContextServie emit the languageChanged event.
    this.sjiAppContextService.updateSelectedlanguage(lang);

    if (lang == '4') {
      this.translateService.use(Languages.Chinese_CHS);
      this.isShowConvidFrench = false;
      this.isShowConvidEnglish = true;
    } else if (lang == '3') {
      this.translateService.use(Languages.Spanish_US);
      this.isShowConvidFrench = false;
      this.isShowConvidEnglish = true;
    } else if (lang == '2') {
      this.translateService.use(Languages.French_CA);
      this.isShowConvidFrench = true;
      this.isShowConvidEnglish = false;
    } else {
      this.translateService.use(Languages.English_US);
      this.isShowConvidFrench = false;
      this.isShowConvidEnglish = true;
    }
  }

  public viewCart(isWarehouse: boolean) {
    //console.log('viewCart');
    if (isWarehouse)
      this.router.navigate(['cart/index'], { queryParams: { isWarehouse: isWarehouse } });
    else
      this.router.navigate(['cart/cartIndex'], { queryParams: { isWarehouse: isWarehouse } });
    this.maxShowNav = "maxShowNav";
    this.link_click = null;
  }
  public closeManNa() {
    this.maxShowNav = "maxShowNav";
    this.link_click = null;
  }
  public clickDrop() {
    this.maxShowNav = "maxShowNav";
  }
  public showContainer(cartInfo) {
    this.sJIService.containerCartInfo = cartInfo;
  }
  public showWarehouse(cartInfo) {
    this.sJIService.warehouseCartInfo = cartInfo;
  }
  public showCompany() {
    this.isCompanyDialog = true;
    console.log(this.sjiAppContext)
  }
  public companyDialogClose() {
    this.isCompanyDialog = false;
  }
  public mouseEnter(classId: number) {
    this.link_click = classId;
  }
  public mouseLeave() {
    this.link_click = null;
  }
  public close(){
    this.confirmDialog = false;
    this.securityService.signout();
  }
  /** Get customer list for drop down rendering. */
  private getCustomer() {
    this.sJIService.get(SJI_API_URLS.common_customer).subscribe((data) => {
      this.customerList = data;
      this.customerSourceList = data;

      if (this.customerList == null || this.customerList.length < 1) {
        // work for bug fix #13.
        //this.router.navigate(['userprofile/error']);
        this.noCustomers = true;
      }
      else {
        this.noCustomers = false;
        //
        // If current logon user has assigned company customers.
        //
        // get customershipto from session
        const customerId = this.securityService.getCustomerId();
        if (customerId && customerId != null) {
          this.customerId = customerId;
        }
        if (this.customerId && this.customerId !== '' && this.customerId != null) {
          this.customershipto(this.customerId);
        }
      }
    });
  }


  /**
   * As for Vendor portal （ModuleId = 9 or 10), system need to get logon user's assigned Vendor.
   * Note: Each user should only has one assigned Vendor. (Get record from CompanyVendorUser table)
   * If logon user no assigned vendor, system need to redirect to warinig page (noVendor)
   */
  private getVendor(){
    this.sJIService.get(SJI_API_URLS.common_vendor).subscribe(data => {
      this.verdorList = data;
      if(this.verdorList !== null && this.verdorList.length > 0){
        this.verdorId = this.verdorList[0].key;
      }else{
        this.isPurchase = false;
        this.isVendorIncomingShipment = false;
        this.isVendorInvoiceAndCreditMemo = false;

        //
        //Note: Following modules are associated to Customer Portal.
        //
        if  (!(this.isWarehouse || this.isFactoryDirect || this.isWarrantyClaim
              || this.isNationalClaim || this.isDocumentCenter)) {
          //
          // Only display No Vendor warning message if logon user only assigned "Purchase Order" Module and "Incoming Shipment" Module.
          // if logon user assigned other modules, system will not show "No Vendor" warning message.
          // Such as "Admin" account
          //
          this.router.navigate(['/vendor/noVendor']);
        }
      }
    });
  }

  async customershipto(value): Promise<string> {
    const url = SJI_API_URLS.common_customershipto + `?customer=${value}`;
    const response = await this.sJIService.get(url).toPromise();
    if (response) {
      this.customershiptoList = response;
      this.customershiptoSourceList = response;
      this.customershiptoId = this.securityService.getShipToId();
    }
    return value;
  }

  private lookup_languages() {
    this.sJIService.get(SJI_API_URLS.common_lookup_languages).subscribe((data) => {
      this.languageList = data;
      this.languageId = this.sjiAppContextService.getLanguageId();
    });
  }
  handleCustomerFilter(value){
    this.customerList = this.customerSourceList.filter(
      (s) => s.value.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  handleShipToFilter(value){
    this.customershiptoList = this.customershiptoSourceList.filter(
      (s) => s.value.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
}
