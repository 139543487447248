import { Component, OnInit, OnDestroy, ViewEncapsulation, EventEmitter, Output, ViewChild } from '@angular/core';
//import { ItemChangedEvent } from '@progress/kendo-angular-scrollview';
import { Router, ActivatedRoute } from '@angular/router';
import { searchModel } from '../../order/models/searchModel';
import { SJIService } from '../../services/sji.service';
import { SJI_API_URLS } from '../../_configs/webapiurls';
import { SJIAppContextService } from '../../../core/services/system/sji.appcontext.service';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;
@Component({
  selector: 'promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent {
  /**
  * If no promotions records got from [order].[ItemPromotion] table, emit this event to public.
  * Corporate with home/components/index.component.ts
  */
  @Output() afterNoPromotionFound = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private appContextService: SJIAppContextService,
    private sanitizer: DomSanitizer,
    public sjiService: SJIService
  ) {
    this.getTextBanner();
    this.initImage();
    // Register customer changed event.
    this.appContextService.customerChanged.subscribe((companyCustomerCd: string) => this.customerChanged(companyCustomerCd));
  }

  @ViewChild('coverflow') coverflow;
  @ViewChild('showDetailDialogInput') showDetailDialogInput;

  public that: any;
  public items: ImageInfo[] = [];
  public activeIndex: number = 0;
  public imageInfo: ImageInfo = {};
  public showDetailDialog: string = 'false';
  public searchModel: searchModel = { pageIndex: null, pageSize: null, keywords: null, code: null, size: null, sortBy: null, brandId: null, description: null, categoryId: null, subCategoryId: null, filters: null };
  public appContext: any = {};
  public themeBanner = '';
  public textBanner:any = '';
  isShowBanner: boolean;
  defaultOrderType: string;
  cleanBox: boolean = false;
  isRefresh:boolean = false;
  public initImage() {
    this.appContext = this.appContextService.getAppContext();
    this.defaultOrderType = this.appContext.defaultOrderType;
    if (this.appContext.companyCustomerTheme && this.appContext.companyCustomerTheme.length > 0) {
      this.themeBanner = this.appContext.companyCustomerTheme;
    } else if (this.appContext.companyTheme && this.appContext.companyTheme.length > 0) {
      this.themeBanner = this.appContext.companyTheme;
    }
    if (this.appContext.modules.indexOf(2) > -1) {
      this.sjiService.get(SJI_API_URLS.itemPromotion_getItemPromotion).subscribe(reslut => {
        this.items = reslut as ImageInfo[];
        if (this.items != null && this.items.length > 0) {
          // There is promotions.
          this.isShowBanner = false;
          //this.afterNoPromotionFound.emit(false);
          setTimeout(() => { this.createCoverflow(); }, 100);
        } else {
          this.isShowBanner = true;
          // Raised no promotion event.
          //this.afterNoPromotionFound.emit(true);
        }
      });
    } else {
      this.isShowBanner = true;
    }

  }
  public createCoverflow() {
    const temp = this;
    this.that = $(this.coverflow.nativeElement).coverflow({
      index: this.activeIndex,
      select: function (event, cover, index) {
        temp.activeIndex = index;
        temp.onShowChange();
      }
    });
    if(this.isRefresh)
      this.that.data('vanderleeCoverflow').refresh();
  }

  public clickImage(index: number) {
    this.activeIndex = index;
    this.that.data('vanderleeCoverflow').index(index);
  }

  public nextImage(indent: number) {
    let index = this.activeIndex as number;
    index += indent;
    if (index > this.items.length - indent || index < 0)
      return;

    this.that.data('vanderleeCoverflow').index(index);
  }

  public detailClose() {
    this.showDetailDialog = 'false';
  }
  public search() {
    if (this.appContext.modules.indexOf(2) > -1 && this.defaultOrderType != 'DC')
      this.router.navigate(['order/index'], { queryParams: this.searchModel });
    else if (this.appContext.modules.indexOf(1) > -1 && this.defaultOrderType == 'DC')
      this.router.navigate(['order/directContainer'], { queryParams: this.searchModel });
  }
  public productsSearch(){
    this.searchModel.code=this.imageInfo.navItemCd;
    this.searchModel.brandId=this.imageInfo.itemBrandCd;
    this.searchModel.categoryId=this.imageInfo.itemCategoryCd;
    this.searchModel.size=this.imageInfo.size;
    this.searchModel.subCategoryId=this.imageInfo.itemSubCategoryCd;
    console.log(this.imageInfo,this.searchModel);
    this.search();
  }
  public onShowChange() {
    this.imageInfo = this.items[this.activeIndex];
  }
  public onChangeIndex() {
    if (this.showDetailDialogInput.nativeElement.value)
      this.showDetailDialog = this.showDetailDialogInput.nativeElement.value;
    this.onShowChange();
  }

  public keyUpSearch() {
    this.search();
  }
  public inputChange() {
    if (this.searchModel.keywords != null && this.searchModel.keywords !="") {
      this.cleanBox = true;
    } else {
      this.cleanBox = false;
    }
  }
  customerChanged(companyCustomerCd: string) {
    // this.coverflow.clear();
    this.isRefresh=true;
    this.initImage();

  }
  getTextBanner(){
    this.sjiService.get(SJI_API_URLS.common_company_gettextbanner).subscribe((data: string) => {
      this.textBanner = this.sanitizer.bypassSecurityTrustHtml(data);
    });
  }
}
export class ImageInfo {
  id?: string;
  title?: string;
  description?: string;
  imagePath?: string;
  startDate?: Date;
  endDate?: Date;
  /** It references to field "ItemCd" in table [Order].[Item].  */
  itemCd?: string;
  /** It references to field "NavItemCd" in table [Order].[Item] */
  navItemCd?: string;
  itemBrandCd?: string;
  itemCategoryCd?: string;
  itemSubCategoryCd?: string;
  size?: string;
  src?: string;
  isShowLink?:boolean;
}
